import { Text } from '../../../../../../components'
import { Row } from '../../../../../../containers'
import { formatBodyWeight } from '../../../../../../utils/format'

const WeeklyBodyWeightListFooter = ({ weekAverage }) => {
  return (
    <Row sx={{ mt: 0.5, justifyContent: 'space-between' }}>
      <Text sx={{ fontWeight: 'bold' }}>Week Average</Text>
      <Text sx={{ fontWeight: 'bold' }}>{formatBodyWeight(weekAverage)}</Text>
    </Row>
  )
}

export default WeeklyBodyWeightListFooter
