import { useDateContext } from '@contexts'
import { CreateWorkoutCardContent as CreateWorkoutCardContentComponent } from '@modules/Workout/components'
import { useCreateWorkout } from '@modules/Workout/hooks'
import { formatDateForInput } from '@utils/date'

const createDefaultWorkout = (date) => {
  const formattedDate = formatDateForInput(date)
  return {
    startDate: formattedDate,
    endDate: formattedDate,
  }
}

export const CreateWorkoutCardContent = ({ isLoading, workoutTypes }) => {
  const createWorkoutApi = useCreateWorkout()
  const { date } = useDateContext()

  const loading = isLoading || createWorkoutApi.isLoading

  return (
    <CreateWorkoutCardContentComponent
      isLoading={loading}
      onCreateWorkout={createWorkoutApi.createWorkout}
      workoutTypes={workoutTypes}
      workout={createDefaultWorkout(date)}
    />
  )
}
