import { MyFitAppApi } from '@apis/MyFitApp'
import { usePostQuery } from '@hooks'

export const useCreateTrainingPlan = () => {
  const { mutate: createTrainingPlan, ...rest } = usePostQuery(
    (data) => MyFitAppApi.createTrainingPlan(data),
    {
      successMessage: 'training plan created successfully!',
      errorMessage: 'training plan could not be created!',
    }
  )

  return {
    createTrainingPlan,
    ...rest,
  }
}
