import { Card, CardTitle } from '../../../../components'
import { WeeklyBodyWeightCardContent } from './components'

const WeeklyBodyWeightCard = ({ bodyWeights, weekAverage, loading }) => {
  return (
    <Card aria-label="Weekly Body Weight Card">
      <CardTitle>Weekly Body Weight</CardTitle>
      <WeeklyBodyWeightCardContent
        loading={loading}
        bodyWeights={bodyWeights}
        weekAverage={weekAverage}
      />
    </Card>
  )
}

export default WeeklyBodyWeightCard
