import { useForm } from 'react-hook-form'
import { Button, TextField } from '../../../../components'
import { Column } from '../../../../containers'
import WorkoutTypesSelect from '../WorkoutTypesSelect/WorkoutTypesSelect'
import date from 'date-and-time'
import { useEffect } from 'react'

const EditWorkoutForm = ({ workout = {}, workoutTypes, onSubmit }) => {
  const defaultValues = {
    ...workout,
    startDate: date.format(new Date(workout?.startDate), 'YYYY-MM-DDTHH:mm:ss'),
    endDate: date.format(new Date(workout?.endDate), 'YYYY-MM-DDTHH:mm:ss'),
  }

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
  })

  const name = watch('name')
  const workoutTypeId = watch('type.id')

  const submit = ({ startDate, endDate, ...data }) => {
    const pattern = date.compile('YYYY-MM-DDTHH:mm:ssZ')
    onSubmit({
      ...data,
      startDate: date.parse(startDate, pattern).toISOString(),
      endDate: date.parse(endDate, pattern).toISOString(),
    })
  }

  useEffect(() => {
    if (name === '') {
      const workoutType = workoutTypes.find((w) => w.id === workoutTypeId)
      if (workoutType) {
        setValue('name', workoutType.name)
      }
    }
  }, [workoutTypeId])

  return (
    <Column
      component="form"
      onSubmit={handleSubmit(submit)}
      sx={{ p: 1, pt: 0, flex: 1 }}
    >
      <TextField
        label="Name"
        helperText={errors?.name?.message}
        {...register('name', {
          required: 'Name required',
        })}
      />
      {!workout?.trainingPlanDayId && (
        <WorkoutTypesSelect
          control={control}
          name="type.id"
          rules={{
            required: 'workout type required',
          }}
          helperText={errors?.workoutTypeId?.message}
          workoutTypes={workoutTypes}
        />
      )}
      <TextField
        label="Start Date"
        type="datetime-local"
        helperText={errors?.startDate?.message}
        inputProps={{
          step: 1,
        }}
        {...register('startDate', {
          required: 'Start Date required',
        })}
      />
      <TextField
        label="End Date"
        type="datetime-local"
        inputProps={{
          step: 1,
        }}
        helperText={errors?.endDate?.message}
        {...register('endDate', {
          required: 'End Date required',
        })}
      />
      <Button type="submit">SAVE</Button>
    </Column>
  )
}

export default EditWorkoutForm
