import { NumberBadge } from '@components'
import { AddPhotoAlternate, MoreVert } from '@mui/icons-material'
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import { useState } from 'react'

const ListPageItem = ({
  primary,
  secondary,
  item,
  image,
  name,
  count,
  hasImage,
  editable,
  deletable,
  onClick,
  onSelectImage,
  onAddImage,
  onEdit,
  onDelete,
}) => {
  const [anchor, setAnchor] = useState(null)
  const isMenuOpen = Boolean(anchor)

  const handleClickItem = (e, item) => {
    if (onClick && !e.isDefaultPrevented()) {
      onClick(item)
    }
  }

  const handleSelectImage = (e, item) => {
    e.preventDefault()
    onSelectImage(item)
  }

  const handleAddImage = (e, item) => {
    e.preventDefault()
    if (onAddImage) {
      onAddImage(item)
    }
  }

  const handleClickMenu = (event) => {
    setAnchor(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchor(null)
  }

  const handleEdit = (item) => {
    handleCloseMenu()
    if (onEdit) {
      onEdit(item)
    }
  }

  const handleDelete = (item) => {
    handleCloseMenu()
    if (onDelete) {
      onDelete(item)
    }
  }

  return (
    <ListItem
      button={onClick !== undefined}
      onClick={(e) => handleClickItem(e, item)}
      sx={{ mb: 1, borderRadius: 5, bgcolor: 'background.card' }}
    >
      {image ? (
        <ListItemAvatar onClick={(e) => handleSelectImage(e, item)}>
          <Avatar
            alt={name}
            title={name}
            src={image.src}
          />
        </ListItemAvatar>
      ) : (
        hasImage &&
        onAddImage && (
          <IconButton
            sx={{ mr: 2 }}
            onClick={(e) => handleAddImage(e, item)}
          >
            <AddPhotoAlternate />
          </IconButton>
        )
      )}
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{ sx: { color: 'text.primary' } }}
      />
      <ListItemSecondaryAction>
        {(editable || deletable) && (
          <IconButton onClick={handleClickMenu}>
            <MoreVert />
          </IconButton>
        )}
        <Menu
          anchorEl={anchor}
          open={isMenuOpen}
          onClose={handleCloseMenu}
        >
          {editable && (
            <MenuItem onClick={() => handleEdit(item)}>Edit</MenuItem>
          )}
          {deletable && (
            <MenuItem onClick={() => handleDelete(item)}>Delete</MenuItem>
          )}
        </Menu>
        {count > 0 && <NumberBadge>{count}</NumberBadge>}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default ListPageItem
