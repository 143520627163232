import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Row } from '../../containers'
import { formatDate } from '../../utils/format'
import Text from '../Text/Text'

const DatePicker = ({ date, onIncreaseDate, onDecreaseDate }) => {
  return (
    <Row sx={{ justifyContent: 'space-between', p: 2 }}>
      <IconButton
        onClick={onDecreaseDate}
        aria-label="Last Day"
      >
        <ChevronLeft />
      </IconButton>
      <Text
        role="heading"
        aria-label="Current Date"
      >
        {formatDate(date, {
          weekday: 'long',
        })}
      </Text>
      <IconButton
        onClick={onIncreaseDate}
        aria-label="Next Day"
      >
        <ChevronRight />
      </IconButton>
    </Row>
  )
}

export default DatePicker
