import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useMemo } from 'react'
import { useThemeContext } from '@contexts'

const Theme = ({ children }) => {
  const { theme } = useThemeContext()

  const currentTheme = useMemo(() => createTheme(theme), [theme])

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
}

export default Theme
