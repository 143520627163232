import useBodyWeightStats from '../../hooks/useBodyWeightStats/useBodyWeightStats'
import MonthlyBodyWeightCardComponent from '../../components/MonthlyBodyWeightCard/MonthlyBodyWeightCard'

const MonthlyBodyWeightCard = () => {
  const { bodyWeightStats, loading } = useBodyWeightStats()

  return (
    <MonthlyBodyWeightCardComponent
      bodyWeightStats={bodyWeightStats}
      loading={loading}
    />
  )
}

export default MonthlyBodyWeightCard
