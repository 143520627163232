import { Delete } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import {
  Button,
  CardContent,
  Text,
  withCircularProgress,
} from '../../../../../../components'
import { Column, Row } from '../../../../../../containers'
import { formatBodyFat, formatDate } from '../../../../../../utils/format'

const BodyFatCardContent = ({ bodyFat, onDelete, onCreate }) => {
  const handleDeleteBodyFat = () => {
    if (onDelete) {
      onDelete(bodyFat)
    }
  }

  return (
    <CardContent>
      {bodyFat ? (
        <Row sx={{ justifyContent: 'space-between' }}>
          <Column>
            <Text>{formatBodyFat(bodyFat?.bodyFat)}</Text>
            <Text variant="caption">{formatDate(new Date(bodyFat?.date))}</Text>
          </Column>
          <IconButton onClick={handleDeleteBodyFat}>
            <Delete />
          </IconButton>
        </Row>
      ) : (
        <Row sx={{ justifyContent: 'center' }}>
          <Button onClick={onCreate}>Add Body Fat</Button>
        </Row>
      )}
    </CardContent>
  )
}

const circularProgressProps = {
  'aria-label': 'load Body Fat',
}

export default withCircularProgress(BodyFatCardContent, circularProgressProps)
