import { MyFitAppApi } from '@apis/MyFitApp'
import { usePostQuery } from '@hooks'

export const useUpdateTrainingPlan = () => {
  const { mutate: updateTrainingPlan, ...rest } = usePostQuery(
    (data) => MyFitAppApi.updateTrainingPlan(data),
    {
      invalidateQueries: (_, { id }) => [['trainingPlans', id]],
      successMessage: 'training plan updated successfully!',
      errorMessage: 'training plan could not be updated!',
    }
  )

  return {
    updateTrainingPlan,
    ...rest,
  }
}
