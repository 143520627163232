import { Card, CardTitle } from '../../../../components'
import { MonthlyBodyWeightCardContent } from './components'

const MonthlyBodyWeightCard = ({ bodyWeightStats, loading }) => {
  return (
    <Card aria-label="Monthly Body Weight Card">
      <CardTitle>Monthly Body Weight</CardTitle>
      <MonthlyBodyWeightCardContent
        loading={loading}
        bodyWeightStats={bodyWeightStats}
      />
    </Card>
  )
}

export default MonthlyBodyWeightCard
