import { useGetGymExercises } from '@modules/Gyms/hooks'

export const withGetGymExercises =
  (Component) =>
  ({ isLoading = false, ...props }) => {
    const { gymId, muscleId = null } = props

    const getGymExercisesApi = useGetGymExercises({ gymId, muscleId })

    return (
      <Component
        isLoading={isLoading || getGymExercisesApi.isLoading}
        exercises={getGymExercisesApi.exercises}
        {...props}
      />
    )
  }
