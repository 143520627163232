import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { IconButton, TextField } from '@mui/material'
import { Row } from '../../containers'

const NumberField = ({
  name,
  type = 'integer',
  value,
  step = 1,
  onChange,
  label,
  ...props
}) => {
  const handleIncrease = () => {
    const currentValue = value ? value : 0
    onChange(name, currentValue + step)
  }

  const handleDecrease = () => {
    const currentValue = value ? value : 0
    onChange(name, currentValue - step)
  }

  const handleChange = (e) => {
    const value = e.currentTarget.value
    switch (type) {
      case 'integer':
        onChange(name, parseInt(value))
        break
      case 'double':
        onChange(name, parseFloat(value))
        break
      default:
        break
    }
  }

  return (
    <Row>
      <IconButton
        aria-label={`Decrease ${label}`}
        onClick={handleDecrease}
      >
        <ChevronLeft />
      </IconButton>
      <TextField
        label={label}
        size="small"
        type="number"
        margin="dense"
        inputProps={{
          step,
          sx: { textAlign: 'center' },
          'aria-label': label,
        }}
        sx={{ width: 100 }}
        value={value}
        onChange={handleChange}
        {...props}
      />
      <IconButton
        aria-label={`Increase ${label}`}
        onClick={handleIncrease}
      >
        <ChevronRight />
      </IconButton>
    </Row>
  )
}

export default NumberField
