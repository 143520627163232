import { MyFitAppApi } from '@apis/MyFitApp'
import { useDateContext } from '@contexts'
import { usePostQuery } from '@hooks/index'
import { formatToIsoDatetime } from '@utils/date'

export const useStartWorkout = () => {
  const { isoDate, lastDays } = useDateContext()

  const { mutate: startWorkout, ...rest } = usePostQuery(
    ({
      accessToken,
      name,
      gymId,
      trainingPlanDayId,
      workoutTypeId,
      lockerNumber = null,
    }) => {
      const startDate = formatToIsoDatetime()
      return MyFitAppApi.startWorkout({
        accessToken,
        name,
        startDate,
        workoutTypeId,
        lockerNumber,
        gymId,
        trainingPlanDayId,
      })
    },
    {
      invalidateQueries: () => [
        ['workouts', isoDate],
        ['workouts', lastDays.startDate, lastDays.endDate],
      ],
    }
  )

  return {
    startWorkout,
    ...rest,
  }
}
