import { fetchApi } from '../utils'

export const getUser = async ({ accessToken }) => {
  return await fetchApi('/users', { accessToken })
}

export const updateUser = async ({ accessToken, homeGymId }) => {
  await fetchApi(`/users`, {
    method: 'PUT',
    accessToken,
    body: { homeGymId },
  })
}

export const updateHomeGym = async ({ accessToken, homeGymId }) => {
  await fetchApi(`/users/homeGym`, {
    method: 'PUT',
    accessToken,
    body: { homeGymId },
  })
}

export const acceptFriendRequest = async ({ accessToken, userId }) => {
  await fetchApi('/users/acceptFriendRequest', {
    method: 'PUT',
    accessToken,
    body: { userId },
  })
}

export const rejectFriendRequest = async ({ accessToken, userId }) => {
  await fetchApi('/users/rejectFriendRequest', {
    method: 'PUT',
    accessToken,
    body: { userId },
  })
}

export const removeFriend = async ({ accessToken, userId }) => {
  await fetchApi('/users/removeFriend', {
    method: 'PUT',
    accessToken,
    body: { userId },
  })
}

export const getUsers = async ({ accessToken }) => {
  const { users } = await fetchApi('/users/all', {
    accessToken,
  })
  return users
}

export const sendFriendRequest = async ({ accessToken, userId }) => {
  await fetchApi('/users/sendFriendRequest', {
    method: 'POST',
    accessToken,
    body: {
      userId,
    },
  })
}
