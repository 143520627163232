import { Card, CardTitle } from '../../../../components'
import { BodyFatCardContent } from './components'

const BodyFatCard = ({ onDelete, loading, bodyFat, onCreate }) => {
  const handleDeleteBodyWeight = (bodyFat) => {
    if (window.confirm('Do yout really want to delete the body fat?')) {
      onDelete(bodyFat)
    }
  }

  return (
    <Card aria-label="Body Fat Card">
      <CardTitle aria-label="Body Fat">Body Fat</CardTitle>
      <BodyFatCardContent
        loading={loading}
        bodyFat={bodyFat}
        onDelete={handleDeleteBodyWeight}
        onCreate={onCreate}
      />
    </Card>
  )
}

export default BodyFatCard
