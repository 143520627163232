import { Delete } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import {
  Button,
  CardContent,
  Text,
  withCircularProgress,
} from '../../../../../../components'
import { Column, Row } from '../../../../../../containers'
import { formatBodyWeight, formatDate } from '../../../../../../utils/format'

const BodyWeightCardContent = ({ bodyWeight, onDelete, onCreate }) => {
  const handleDeleteBodyWeight = () => {
    if (onDelete) {
      onDelete(bodyWeight)
    }
  }

  return (
    <CardContent>
      {bodyWeight ? (
        <Row sx={{ justifyContent: 'space-between' }}>
          <Column>
            <Text>{formatBodyWeight(bodyWeight?.weight)}</Text>
            <Text variant="caption">
              {formatDate(new Date(bodyWeight?.date))}
            </Text>
          </Column>
          <IconButton
            aria-label="Delete Body Weight"
            onClick={handleDeleteBodyWeight}
          >
            <Delete />
          </IconButton>
        </Row>
      ) : (
        <Row sx={{ justifyContent: 'center' }}>
          <Button
            onClick={onCreate}
            aria-label="Add Body Weight"
          >
            Add Body Weight
          </Button>
        </Row>
      )}
    </CardContent>
  )
}

const circularProgressProps = {
  'aria-label': 'load Body Weights',
}

export default withCircularProgress(
  BodyWeightCardContent,
  circularProgressProps
)
