import { Dialog } from '..'

const FormDialog = ({ onConfirm, onClose, children, ...props }) => {
  const handleConfirm = (data) => {
    if (onConfirm) {
      onConfirm(data)
    }
    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog
      onClose={onClose}
      {...props}
    >
      {children({ onSubmit: handleConfirm })}
    </Dialog>
  )
}

export default FormDialog
