import { fetchApi } from '../utils'

// --- CREATE ------------
// --- READ   ------------

export const getMuscles = async ({ accessToken, workoutId }) => {
  const { muscles } = await fetchApi(`/muscles`, {
    accessToken,
    params: {
      workoutId,
    },
  })
  return muscles
}

export const getWeeklyWorkload = async ({
  accessToken,
  startDate,
  endDate,
}) => {
  const { workload } = await fetchApi(`/muscles/workload`, {
    accessToken,
    params: {
      startDate,
      endDate,
    },
  })
  return workload
}

// --- UPDATE ------------
// --- DELETE ------------
