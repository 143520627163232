import { useEffect, useMemo } from 'react'
import useBodyWeights from '../useBodyWeights/useBodyWeights'

const useWeeklyBodyWeights = ({ startDate, endDate }) => {
  const { getBodyWeights, bodyWeights, loading } = useBodyWeights()

  useEffect(() => {
    getBodyWeights({ startDate, endDate })
  }, [getBodyWeights, startDate, endDate])

  const weekAverage = useMemo(
    () =>
      bodyWeights && bodyWeights.length > 0
        ? bodyWeights.reduce((sum, bodyWeight) => sum + bodyWeight.weight, 0) /
          bodyWeights.length
        : 0,
    [bodyWeights]
  )

  return {
    bodyWeights,
    weekAverage,
    loading,
  }
}

export default useWeeklyBodyWeights
