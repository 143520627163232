import { Text } from '@components'
import { Row } from '@containers'
import { Button } from '@mui/material'
import { isSameDate } from '@utils/date'

export const LastWorkoutCards = ({ date, onSelect, lastWorkoutDays }) => {
  return (
    <Row sx={{ justifyContent: 'space-between', m: 3, mt: 0, mb: 3 }}>
      {lastWorkoutDays.map((lastWorkoutDay, i) => {
        return (
          <LastWorkoutCard
            key={i}
            date={date}
            lastWorkoutDay={lastWorkoutDay}
            onClick={onSelect}
          />
        )
      })}
    </Row>
  )
}

const LastWorkoutCard = ({ date: currentDate, lastWorkoutDay, onClick }) => {
  const { date, workout } = lastWorkoutDay

  const handleClick = () => {
    onClick(date)
  }

  const label = date.toLocaleDateString('de-DE', { weekday: 'short' })

  const isWorkoutDay = workout !== undefined

  const isRunningWorkout = workout?.endDate === null

  const color = isSameDate(currentDate, date)
    ? 'secondary.main'
    : isWorkoutDay
    ? 'primary.light'
    : '#aaa'

  return (
    <Button
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px',
        borderStyle: isRunningWorkout ? 'dotted' : 'solid',
        borderColor: color,
        borderRadius: '50%',
        height: 40,
        width: 40,
        minWidth: 40,
        textTransform: 'none',
        p: 0,
      }}
      onClick={handleClick}
    >
      <Text sx={{ color }}>{label}</Text>
    </Button>
  )
}
