import { Dialog } from '../../../../components'
import EditWorkoutForm from '../EditWorkoutForm/EditWorkoutForm'

const EditWorkoutDialog = ({
  onClose,
  onConfirm,
  workoutTypes,
  workout,
  ...props
}) => {
  const handleConfirm = (data) => {
    onConfirm(data)
    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog
      title="Edit Workout"
      onClose={onClose}
      {...props}
    >
      <EditWorkoutForm
        onSubmit={handleConfirm}
        workout={workout}
        workoutTypes={workoutTypes}
      />
    </Dialog>
  )
}

export default EditWorkoutDialog
