import { forwardRef } from 'react'
import { Column } from '../../containers'

const Card = forwardRef(({ children, sx, ...props }, ref) => {
  return (
    <Column
      ref={ref}
      component="article"
      aria-label="Monthly Body Weight Card"
      sx={{
        mt: 1,
        borderRadius: 5,
        boxShadow: 6,
        p: 2,
        bgcolor: 'background.card',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Column>
  )
})

export default Card
