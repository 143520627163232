import useDialog from '@hooks/useDialog/useDialog'
import { GymDialog } from '@modules/Gyms/components'
import { Add } from '@mui/icons-material'
import { Fab } from '@mui/material'

export const CreateGymFab = ({ onCreate, isLoading = false }) => {
  const [isOpen, openDialog, closeDialog] = useDialog()

  return (
    <>
      <Fab
        color="primary"
        onClick={openDialog}
        sx={{ position: 'absolute', bottom: 24, right: 24 }}
      >
        <Add />
      </Fab>
      <GymDialog
        title="Create Gym"
        isOpen={isOpen}
        isLoading={isLoading}
        onCreate={onCreate}
        onClose={closeDialog}
      />
    </>
  )
}
