import useBodyFat from '../../hooks/useBodyFat/useBodyFat'
import BodyFatCardComponent from '../../components/BodyFatCard/BodyFatCard'
import useDialog from '../../../../hooks/useDialog/useDialog'
import BodyFatDialog from '../../components/BodyFatDialog/BodyFatDialog'
import { formatDate } from '../../../../utils/format'

const BodyFatCard = ({ date }) => {
  const [isDialogOpen, openDialog, closeDialog] = useDialog(false)
  const { bodyFat, loading, createBodyFat, deleteBodyFat } = useBodyFat({
    date,
  })

  return (
    <>
      <BodyFatCardComponent
        loading={loading}
        bodyFat={bodyFat}
        onDelete={deleteBodyFat}
        onCreate={openDialog}
      />
      <BodyFatDialog
        title={formatDate(date)}
        open={isDialogOpen}
        onClose={closeDialog}
        onConfirm={createBodyFat}
      />
    </>
  )
}

export default BodyFatCard
