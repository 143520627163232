import { Button, Card, Form, Text, TextField } from '@components'
import { Column, GoBackPage, Row, ScrollContainer } from '@containers'
import {
  useCreateTrainingPlan,
  useGetTrainingPlan,
  useUpdateTrainingPlan,
} from '@modules/TrainingPlans/hooks'
import { Add, Check, Delete, Remove } from '@mui/icons-material'
import { Fab, IconButton, LinearProgress } from '@mui/material'
import { useParams } from 'react-router-dom'
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { forwardRef } from 'react'
import { ExercisePicker } from '@modules/Exercises/containers'
import { useMediaSize } from '@hooks'

const EditTrainingPlanPage = () => {
  const { trainingPlanId = null } = useParams()
  const isNew = trainingPlanId === 'create'

  return isNew ? (
    <CreateTrainingPlan />
  ) : (
    <UpdateTrainingPlan trainingPlanId={parseInt(trainingPlanId)} />
  )
}

const CreateTrainingPlan = () => {
  return <EditTrainingPlan />
}

const UpdateTrainingPlan = ({ trainingPlanId }) => {
  const { trainingPlan, isError, isFetching } =
    useGetTrainingPlan(trainingPlanId)

  return (
    <EditTrainingPlan
      trainingPlan={trainingPlan}
      isLoading={isFetching}
      isError={isError}
    />
  )
}

const EditTrainingPlan = ({
  trainingPlan = null,
  isLoading = false,
  isError = false,
}) => {
  const createTrainingPlanApi = useCreateTrainingPlan()
  const updateTrainingPlanApi = useUpdateTrainingPlan()

  return (
    <GoBackPage
      title={trainingPlan ? 'Edit Training Plan' : 'Create Training Plan'}
      isLoading={isLoading}
    >
      {isError ? (
        <Text align="center">Training Plan not found</Text>
      ) : (
        <TrainingPlanForm
          defaultValues={trainingPlan}
          isLoading={
            createTrainingPlanApi.isLoading || updateTrainingPlanApi.isLoading
          }
          onCreate={createTrainingPlanApi.createTrainingPlan}
          onUpdate={updateTrainingPlanApi.updateTrainingPlan}
        />
      )}
    </GoBackPage>
  )
}

const TrainingPlanForm = ({ defaultValues, onCreate, onUpdate, isLoading }) => {
  const formApi = useForm({ defaultValues })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formApi

  const handleConfirm = (data) => {
    if (data.id) {
      onUpdate(data)
    } else {
      onCreate(data)
    }
  }

  return (
    <FormProvider {...formApi}>
      {isLoading && <LinearProgress />}
      <Form
        sx={{
          p: 1,
          position: 'relative',
          height: '100%',
          overflowY: 'hidden',
          overflowX: 'hidden',
          boxSizing: 'border-box',
        }}
        onSubmit={handleSubmit(handleConfirm)}
      >
        <TextField
          label="Name"
          helperText={errors?.name?.message}
          {...register('name', {
            required: 'Name required',
          })}
        />
        <TrainingDayList />
        <Fab
          color="primary"
          type="submit"
          sx={{ position: 'absolute', bottom: 24, right: 24 }}
        >
          <Check />
        </Fab>
      </Form>
    </FormProvider>
  )
}

const TrainingDayList = () => {
  const { control } = useFormContext()
  const { isDesktop } = useMediaSize()

  const {
    fields: days,
    append: addDay,
    remove: removeDay,
  } = useFieldArray({
    control,
    name: 'days',
    keyName: '_id',
  })

  const handleAddTrainingDay = () => {
    addDay({
      name: '',
    })
  }

  const handleRemoveTrainingDay = (i) => () => {
    removeDay(i)
  }

  return (
    <ScrollContainer horizontal={isDesktop}>
      {days.map((day, i) => (
        <TrainingDayCard
          key={day._id}
          name={`days.${i}`}
          onDelete={handleRemoveTrainingDay(i)}
          sx={
            isDesktop ? (i > 0 ? { width: 300, ml: 1 } : { width: 300 }) : null
          }
        />
      ))}
      <Row sx={{ justifyContent: 'center', pt: 4, pb: 2.5 }}>
        <Button onClick={handleAddTrainingDay}>Add Training Day</Button>
      </Row>
    </ScrollContainer>
  )
}

const TrainingDayCard = forwardRef(({ name, onDelete, sx, ...props }, ref) => {
  const { control, register } = useFormContext()
  const { isDesktop } = useMediaSize()

  const {
    fields: exercises,
    append: addExercise,
    remove: removeExercise,
  } = useFieldArray({
    control,
    name: `${name}.exercises`,
    keyName: '_id',
  })

  const handleAddExercise = ({ id, name }) => {
    console.log({ id, name })
    addExercise({ exerciseId: id, name })
  }

  const handleDeleteExercise = (i) => () => {
    removeExercise(i)
  }

  return (
    <Card
      ref={ref}
      sx={sx}
      {...props}
    >
      <Row sx={{ justifyContent: 'space-between' }}>
        <TextField
          variant="standard"
          {...register(`${name}.name`, {
            required: 'Name required',
          })}
          fullWidth
        />
        <Row>
          <ExercisePicker onSelect={handleAddExercise} />
          <IconButton onClick={onDelete}>
            <Delete />
          </IconButton>
        </Row>
      </Row>
      {isDesktop ? (
        <ScrollContainer>
          {exercises.map((exercise, i) => (
            <TrainingPlanDayExerciseCard
              key={exercise._id}
              name={`${name}.exercises.${i}`}
              exercise={exercise}
              onDelete={handleDeleteExercise(i)}
            />
          ))}
        </ScrollContainer>
      ) : (
        <Column>
          {exercises.map((exercise, i) => (
            <TrainingPlanDayExerciseCard
              key={exercise._id}
              name={`${name}.exercises.${i}`}
              exercise={exercise}
              onDelete={handleDeleteExercise(i)}
            />
          ))}
        </Column>
      )}
    </Card>
  )
})

const TrainingPlanDayExerciseCard = ({ name, exercise, onDelete }) => {
  const { control, register } = useFormContext()

  const {
    fields: sets,
    append: addSet,
    remove: removeSet,
  } = useFieldArray({
    control,
    name: `${name}.sets`,
    keyName: '_id',
  })

  const handleRemoveSet = (i) => () => {
    removeSet(i)
  }

  const handleAddSet = () => {
    addSet({ reps: null, rpe: 8 }, { shouldFocus: true })
  }

  return (
    <Card>
      <Row sx={{ justifyContent: 'space-between' }}>
        <Text variant="caption">{exercise.name}</Text>
        <Row>
          <IconButton onClick={handleAddSet}>
            <Add />
          </IconButton>
          <IconButton onClick={onDelete}>
            <Delete />
          </IconButton>
        </Row>
      </Row>
      {sets.map((set, i) => (
        <Row key={set._id}>
          <IconButton
            size="small"
            color="error"
            onClick={handleRemoveSet(i)}
          >
            <Remove />
          </IconButton>
          <TextField
            size="small"
            margin="none"
            type="number"
            placeholder="Reps"
            sx={{ ml: 1 }}
            {...register(`${name}.sets.${i}.reps`, {
              required: 'Reps required',
              valueAsNumber: true,
            })}
          />
          <TextField
            size="small"
            margin="none"
            type="number"
            placeholder="RPE"
            sx={{ ml: 1 }}
            inputProps={{
              step: 0.5,
            }}
            {...register(`${name}.sets.${i}.rpe`, {
              required: 'Reps required',
              valueAsNumber: true,
              min: {
                value: 0,
                message: 'RPE must be positive',
              },
              max: {
                value: 10,
                message: 'RPE must be smaller/equals 10',
              },
            })}
          />
        </Row>
      ))}
    </Card>
  )
}

export default EditTrainingPlanPage
