import { Button as MaterialButton } from '@mui/material'

const Button = ({ sx, color = 'primary', children, ...props }) => {
  return (
    <MaterialButton
      color={color}
      variant="contained"
      sx={{ borderRadius: 5, ...sx }}
      {...props}
    >
      {children}
    </MaterialButton>
  )
}

export default Button
