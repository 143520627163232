import { useGetExercises } from '@modules/Exercises/hooks'
import { ExerciseList as ExerciseListComponent } from '@modules/Exercises/components'

export const ExericseList = ({ muscleId, onSelect }) => {
  const { exercises, isLoading } = useGetExercises({ muscleId })
  return (
    <ExerciseListComponent
      isLoading={isLoading}
      exercises={exercises}
      onSelect={onSelect}
    />
  )
}
