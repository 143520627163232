import { TableCell, TableHead, TableRow } from '@mui/material'

const MonthlyBodyWeightTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          align="left"
          sx={{ p: 0.5 }}
        >
          Week
        </TableCell>
        <TableCell
          align="right"
          sx={{ p: 0.5 }}
        >
          BW
        </TableCell>
        <TableCell
          align="right"
          sx={{ p: 0.5 }}
        >
          BF
        </TableCell>
        <TableCell
          align="right"
          sx={{ p: 0.5 }}
        >
          FFM
        </TableCell>
        <TableCell
          align="right"
          sx={{ p: 0.5 }}
        >
          FM
        </TableCell>
        <TableCell
          align="right"
          sx={{ p: 0.5 }}
        >
          FFMD
        </TableCell>
        <TableCell
          align="right"
          sx={{ p: 0.5 }}
        >
          FMD
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default MonthlyBodyWeightTableHead
