import { MyFitAppApi } from '@apis/MyFitApp'
import { usePostQuery } from '@hooks'

export const useUpdateHomeGym = () => {
  const { mutate: updateHomeGym, ...rest } = usePostQuery(
    ({ accessToken, ...data }) =>
      MyFitAppApi.updateHomeGym({ accessToken, ...data }),
    {
      invalidateQueries: () => [['gyms']],
    }
  )

  return {
    updateHomeGym,
    ...rest,
  }
}
