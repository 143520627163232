import { Box } from '@mui/material'

const Page = ({ sx, children, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: '100%',
        bgcolor: 'background.paper',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default Page
