import { useMemo } from 'react'

const useSearchFilter = (items = [], searchValue, searchFields = []) => {
  return useMemo(() => {
    if (!Array.isArray(items)) return []
    if (!Array.isArray(searchFields)) return []
    if (searchValue === '') return items
    const value = searchValue.toLowerCase()
    return items.filter((item) => {
      return searchFields.some((field) =>
        item[field].toLowerCase().includes(value)
      )
    })
  }, [items, searchValue])
}

export default useSearchFilter
