import { MyFitAppApi } from '@apis/MyFitApp'
import { useGetQuery } from '@hooks'

export const useGetGyms = () => {
  const { data: gyms = [], ...rest } = useGetQuery(
    ['gyms'],
    ({ accessToken }) => MyFitAppApi.getGyms({ accessToken })
  )

  return {
    gyms,
    ...rest,
  }
}
