import { Dialog as MaterialDialog } from '@mui/material'
import Text from '../Text/Text'

const Dialog = ({ open, title, children, onClose, PaperProps, ...props }) => {
  return (
    <MaterialDialog
      open={open}
      role="dialog"
      aria-hidden={!open}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          borderRadius: 5,
          ...PaperProps?.sx,
        },
      }}
      onBackdropClick={onClose}
      {...props}
    >
      {title && <Text sx={{ p: 2 }}>{title}</Text>}
      {children}
    </MaterialDialog>
  )
}

export default Dialog
