import { MyFitAppApi } from '@apis/MyFitApp'
import { usePostQuery } from '@hooks'

export const useCreateGym = () => {
  const { mutate: createGym, ...rest } = usePostQuery(
    ({ accessToken, name }) => MyFitAppApi.createGym({ accessToken, name }),
    {
      invalidateQueries: () => [['gyms']],
    }
  )

  return {
    createGym,
    ...rest,
  }
}
