import { useGetGyms } from '@modules/Gyms/hooks'

export const withGetGyms =
  (Component) =>
  ({ isLoading = false, ...props }) => {
    const getGymsApi = useGetGyms()

    return (
      <Component
        isLoading={isLoading || getGymsApi.isLoading}
        gyms={getGymsApi.gyms}
        {...props}
      />
    )
  }
