import { CircularProgress } from '@mui/material'
import { Column } from '../../containers'

const CardContent = ({ children, sx, isLoading = false, ...props }) => {
  return (
    <Column
      sx={{ mt: 1, alignItems: isLoading ? 'center' : 'stretch', ...sx }}
      {...props}
    >
      {isLoading ? <CircularProgress /> : children}
    </Column>
  )
}

export default CardContent
