import { fetchApi } from '../utils'

// --- CREATE ------------

export const createWorkoutSet = async ({ accessToken, ...body }) => {
  const { workoutSet } = await fetchApi(`/workoutSets`, {
    method: 'POST',
    accessToken,
    body,
  })
  return workoutSet
}

// --- READ   ------------

export const getWorkoutSets = async ({
  accessToken,
  workoutId,
  exerciseId,
}) => {
  const { workoutSets } = await fetchApi(`/workoutSets`, {
    accessToken,
    params: {
      workoutId,
      exerciseId,
    },
  })
  return workoutSets
}

// --- UPDATE ------------

export const updateWorkoutSet = async ({
  accessToken,
  workoutSetId,
  ...body
}) => {
  const { workoutSet } = await fetchApi(`/workoutSets/${workoutSetId}`, {
    method: 'PUT',
    accessToken,
    body,
  })
  return workoutSet
}

// --- DELETE ------------

export const deleteWorkoutSet = async ({ workoutSetId, accessToken }) => {
  return await fetchApi(`/workoutSets/${workoutSetId}`, {
    accessToken,
    method: 'DELETE',
  })
}
