import { Add, Close, Search } from '@mui/icons-material'
import { Fab, IconButton } from '@mui/material'
import { useMemo, useRef, useState } from 'react'
import { ScrollContainer } from '@containers'
import ListPageList from './components/ListPageList/ListPageList'
import GoBackPage from '../GoBackPage/GoBackPage'
import ImageDialog from './components/ImageDialog/ImageDialog'
import ListTabs from './components/ListTabs/ListTabs'
import { TextField } from '../../components'
import useSearch from '../../hooks/useSearch/useSearch'

const ListPage = ({
  title,
  defaultFilterTab,
  dialog: ItemDialog,
  tabs,
  transform,
  hasImage = true,
  onAdd,
  onClickItem,
  onDeleteImage,
  onScrollBottom,
  onCreate,
  onEdit,
  onAddImage,
  onDelete,
  items = [],
  defaultIsSearchbarOpen = false,
  goBackPageProps,
  searchFields = [],
  filter = (item) => item,
}) => {
  const inputRef = useRef()
  const [isSearchbarOpen, setIsSearchbarOpen] = useState(defaultIsSearchbarOpen)

  const [selectedImage, setSelectedImage] = useState(null)
  const [itemForImage, setItemForImage] = useState(null)
  const [editItem, setEditItem] = useState(null)
  const [filterTab, setFilterTab] = useState(defaultFilterTab)
  const [searchFilteredItems, setSearchValue] = useSearch(items, searchFields)

  const editable = Boolean(onEdit)
  const deletable = Boolean(onDelete)
  const imageDeletable = Boolean(onDeleteImage)

  const handleAdd = () => {
    if (onAdd) {
      onAdd()
    } else {
      setEditItem({})
    }
  }

  const handleCloseDialog = () => {
    setEditItem(null)
  }

  const handleChangeFilterTab = (value) => {
    setFilterTab(value)
  }

  const handleSubmit = (data) => {
    if (data?.id) {
      if (onEdit) {
        onEdit(data)
      }
    } else {
      if (onCreate) {
        onCreate(data)
      }
    }
    setEditItem(null)
  }

  const handleSelectImage = (image) => {
    setSelectedImage(image)
  }

  const handleSaveImage = (e) => {
    const image = e.currentTarget.files[0]
    if (image) {
      onAddImage({ image, item: itemForImage })
    }
    setItemForImage(null)
  }

  const handleAddImage = (item) => {
    setItemForImage(item)
    inputRef.current.click()
  }

  const handleDeleteImage = (item) => {
    if (onDeleteImage) {
      onDeleteImage(item)
    }
  }

  const handleUnselectImage = () => {
    setSelectedImage(null)
  }

  const handleEdit = (item) => {
    setEditItem(item)
  }

  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value)
  }

  const handleToggleSearchbar = () => {
    if (!isSearchbarOpen) {
      setSearchValue('')
    }
    setIsSearchbarOpen(!isSearchbarOpen)
  }

  const tabFilteredItems = useMemo(
    () => items.filter((item) => filter(item, { filterTab })),
    [items, filter, filterTab]
  )

  const finalItems = isSearchbarOpen ? searchFilteredItems : tabFilteredItems

  return (
    <GoBackPage
      title={title}
      right={
        <IconButton onClick={handleToggleSearchbar}>
          {isSearchbarOpen ? <Close /> : <Search />}
        </IconButton>
      }
      {...goBackPageProps}
    >
      {isSearchbarOpen ? (
        <TextField
          margin="none"
          autoFocus={true}
          sx={{ p: 1 }}
          placeholder="Search..."
          onChange={handleChangeSearchValue}
        />
      ) : (
        <ListTabs
          tabs={tabs}
          value={filterTab}
          onChange={handleChangeFilterTab}
        />
      )}
      <ScrollContainer onScrollBottom={onScrollBottom}>
        <ListPageList
          items={finalItems}
          transform={transform}
          editable={editable}
          deletable={deletable}
          hasImage={hasImage}
          onClick={onClickItem}
          onSelectImage={handleSelectImage}
          onAddImage={handleAddImage}
          onEdit={handleEdit}
          onDelete={onDelete}
        />
      </ScrollContainer>
      {(ItemDialog || onAdd) && (
        <Fab
          color="primary"
          onClick={handleAdd}
          sx={{ position: 'absolute', bottom: 24, right: 24 }}
        >
          <Add />
        </Fab>
      )}
      {ItemDialog && editItem && (
        <ItemDialog
          open={true}
          onClose={handleCloseDialog}
          onSubmit={handleSubmit}
          defaultValues={editItem}
        />
      )}
      <ImageDialog
        image={selectedImage}
        onClose={handleUnselectImage}
        onDelete={handleDeleteImage}
        deletable={imageDeletable}
      />
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        accept="image/*"
        onChange={handleSaveImage}
      />
    </GoBackPage>
  )
}

export default ListPage
