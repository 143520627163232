import { Table, TableBody } from '@mui/material'
import { CardContent, withCircularProgress } from '../../../../../../components'
import MonthlyBodyWeightTableHead from '../MonthlyBodyWeightTableHead/MonthlyBodyWeightTableHead'
import MonthlyBodyWeightTableRow from '../MonthlyBodyWeightTableRow/MonthlyBodyWeightTableRow'

const MonthlyBodyWeightCardContent = ({ bodyWeightStats }) => {
  return (
    <CardContent sx={{ overflow: 'scroll' }}>
      <Table
        size="small"
        padding="none"
      >
        <MonthlyBodyWeightTableHead />
        <TableBody sx={{ overflowX: 'scroll' }}>
          {bodyWeightStats &&
            bodyWeightStats.map((bodyWeightStat, i) => {
              return (
                <MonthlyBodyWeightTableRow
                  key={i}
                  {...bodyWeightStat}
                />
              )
            })}
        </TableBody>
      </Table>
    </CardContent>
  )
}

const circularProgressProps = {
  'aria-label': 'load Monthly Body Weights',
}

export default withCircularProgress(
  MonthlyBodyWeightCardContent,
  circularProgressProps
)
