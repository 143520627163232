import { useMemo } from 'react'
import { MyFitAppApi } from '../../../../apis/MyFitApp'
import upperChestImg from '../../../../images/muscles/front/upper_chest.png'
import lowerChestImg from '../../../../images/muscles/front/lower_chest.png'
import sideDeltImg from '../../../../images/muscles/front/side_delt.png'
import frontDeltImg from '../../../../images/muscles/front/front_delt.png'
import bicepsImg from '../../../../images/muscles/front/biceps.png'
import quadsImg from '../../../../images/muscles/front/quads.png'
import absImg from '../../../../images/muscles/front/abs.png'
import upperTrapsImg from '../../../../images/muscles/back/upper_traps.png'
import lowerTrapsImg from '../../../../images/muscles/back/lower_traps.png'
import latsImg from '../../../../images/muscles/back/lats.png'
import lowerBackImg from '../../../../images/muscles/back/lower_back.png'
import rearDeltImg from '../../../../images/muscles/back/rear_delt.png'
import tricepsImg from '../../../../images/muscles/back/triceps.png'
import glutesImg from '../../../../images/muscles/back/glutes.png'
import hamstringsImg from '../../../../images/muscles/back/hamstrings.png'
import calvesImg from '../../../../images/muscles/back/calves.png'

import { useGetQuery } from '@hooks'

const muscleImageMap = {
  1: upperChestImg,
  2: lowerChestImg,
  3: rearDeltImg,
  4: sideDeltImg,
  5: frontDeltImg,
  6: upperTrapsImg,
  7: lowerTrapsImg,
  8: latsImg,
  9: bicepsImg,
  10: tricepsImg,
  11: absImg,
  12: glutesImg,
  13: quadsImg,
  14: hamstringsImg,
  15: calvesImg,
  16: lowerBackImg,
}

export const useMuscles = (config = { workoutId: null }) => {
  const { workoutId = null } = config

  const { data: muscles = [], ...rest } = useGetQuery(
    ['muscles', workoutId],
    ({ accessToken }) => MyFitAppApi.getMuscles({ accessToken, workoutId })
  )

  const transformedMuscles = useMemo(
    () =>
      muscles.map((muscle) => ({
        ...muscle,
        images: [
          {
            src: muscleImageMap[muscle.id],
          },
        ],
      })),
    [muscles]
  )

  return {
    muscles: transformedMuscles,
    ...rest,
  }
}
