import { Button, Form, TextField } from '../../../../../../components'
import { useForm } from 'react-hook-form'

const BodyFatForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const handleData = ({ bodyFat, ...data }) => {
    onSubmit({
      ...data,
      bodyFat: parseFloat(bodyFat),
    })
  }

  return (
    <Form
      onSubmit={handleSubmit(handleData)}
      sx={{ p: 2, pt: 0 }}
    >
      <TextField
        label="Body Fat (%)"
        type="number"
        inputProps={{
          step: 0.1,
          'aria-label': 'bodyFat',
        }}
        autoFocus
        helperText={errors?.bodyFat?.message}
        {...register('bodyFat', {
          required: 'bodyFat required',
        })}
      />
      <Button
        aria-label="Save Body Fat"
        type="submit"
        sx={{ mt: 2 }}
      >
        Save
      </Button>
    </Form>
  )
}

export default BodyFatForm
