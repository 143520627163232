import { ToggleTabs } from '../../../../components'

const ListTabs = ({ tabs, value, onChange }) => {
  return tabs ? (
    <ToggleTabs
      items={tabs}
      value={value}
      onChange={onChange}
    />
  ) : null
}

export default ListTabs
