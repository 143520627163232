import { LinearProgress } from '@mui/material'
import Page from '../Page/Page'
import Row from '../Row/Row'

const HeaderPage = ({ left, right, children, isLoading = false }) => {
  return (
    <Page>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          <Row sx={{ p: 2, justifyContent: 'space-between' }}>
            {left}
            {right}
          </Row>
          {children}
        </>
      )}
    </Page>
  )
}

export default HeaderPage
