import { useCallback, useState } from 'react'
import { MyFitAppApi } from '../../../../apis/MyFitApp'
import { useAccessTokenContext } from '@contexts'
import useAsyncEffect from '../../../../hooks/useAsyncEffect/useAsyncEffect'

const useBodyWeight = ({ date }) => {
  const [accessToken] = useAccessTokenContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [bodyWeight, setBodyWeight] = useState(null)

  const deleteBodyWeight = async (bodyWeight) => {
    setLoading(true)
    try {
      await MyFitAppApi.deleteBodyWeight({
        accessToken,
        bodyWeightId: bodyWeight.id,
      })
      setBodyWeight(null)
    } catch (error) {
      setError('failed to delete body weight')
    }
    setLoading(false)
  }

  const createBodyWeight = async (data) => {
    setLoading(true)
    try {
      const bodyWeight = await MyFitAppApi.createBodyWeight({
        accessToken,
        ...data,
        date: date.toISOString().substring(0, 10),
      })
      setBodyWeight(bodyWeight)
    } catch (error) {
      setError('failed to create body weight')
    }
    setLoading(false)
  }

  const getBodyWeight = useCallback(
    async (signal) => {
      setLoading(true)
      try {
        const bodyWeights = await MyFitAppApi.getBodyWeights({
          accessToken,
          signal,
          date: date.toISOString().substring(0, 10),
        })
        if (bodyWeights.length > 0) {
          setBodyWeight(bodyWeights[0])
        } else {
          setBodyWeight(null)
        }
      } catch (error) {
        setError('failed to get body weight')
      }
      setLoading(false)
    },
    [accessToken, date]
  )

  useAsyncEffect(getBodyWeight)

  return {
    loading,
    error,
    bodyWeight,
    createBodyWeight,
    deleteBodyWeight,
    getBodyWeight,
  }
}

export default useBodyWeight
