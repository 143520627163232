import { fetchApi } from '../utils'

// --- CREATE ------------

export const createBodyWeight = async ({ accessToken, ...body }) => {
  const { bodyWeight } = await fetchApi(`/bodyWeights`, {
    method: 'POST',
    accessToken,
    body,
  })
  return bodyWeight
}

// --- READ   ------------

export const getBodyWeights = async ({
  accessToken,
  date,
  startDate,
  endDate,
  signal,
}) => {
  const { bodyWeights } = await fetchApi(`/bodyWeights`, {
    accessToken,
    signal,
    params: {
      date,
      startDate,
      endDate,
    },
  })
  return bodyWeights
}

export const getBodyWeightStats = async ({ accessToken }) => {
  const { stats } = await fetchApi(`/bodyWeights/stats`, {
    accessToken,
  })
  return stats
}

// --- DELETE ------------

export const deleteBodyWeight = async ({ bodyWeightId, accessToken }) => {
  return await fetchApi(`/bodyWeights/${bodyWeightId}`, {
    accessToken,
    method: 'DELETE',
  })
}
