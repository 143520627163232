import { Column } from '@containers'

const Form = ({ children, ...props }) => {
  return (
    <Column
      component="form"
      {...props}
    >
      {children}
    </Column>
  )
}

export default Form
