import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MaterialSelect,
} from '@mui/material'
import { Controller } from 'react-hook-form'

const Select = ({
  id,
  label,
  name,
  control,
  helperText,
  rules,
  margin = 'normal',
  children,
  defaultValue,
}) => {
  return (
    <FormControl margin={margin}>
      <InputLabel id={id}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <MaterialSelect
            labelId={id}
            error={typeof helperText === 'string'}
            aria-label={label}
            {...field}
          >
            {children}
          </MaterialSelect>
        )}
      />
      {helperText && (
        <FormHelperText sx={{ color: 'error.main' }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Select
