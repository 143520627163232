import { TableCell, TableRow } from '@mui/material'
import {
  formatBodyFat,
  formatBodyWeight,
  formatWeight,
} from '../../../../../../utils/format'

const MonthlyBodyWeightTableRow = ({
  KW,
  avWeight,
  avBodyFat,
  fatFreeMass,
  fatMass,
  fatFreeMassDiff,
  fatMassDiff,
}) => {
  const fatFreeMassDiffSign = fatFreeMassDiff >= 0 ? '+' : ''
  const fatMassDiffSign = fatMassDiff >= 0 ? '+' : ''
  const fatFreeMassDiffColor = fatFreeMassDiff >= 0 ? 'green' : 'red'
  const fatMassDiffColor = fatMassDiff <= 0 ? 'green' : 'red'

  return (
    <TableRow
      key={KW}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell
        align="left"
        sx={{ p: 0.5 }}
      >
        {KW}
      </TableCell>
      <TableCell
        align="right"
        sx={{ p: 0.5 }}
      >
        {formatWeight(avWeight)}
      </TableCell>
      <TableCell
        align="right"
        sx={{ p: 0.5 }}
      >
        {formatBodyFat(avBodyFat)}
      </TableCell>
      <TableCell
        align="right"
        sx={{ p: 0.5 }}
      >
        {formatBodyWeight(fatFreeMass)}
      </TableCell>
      <TableCell
        align="right"
        sx={{ p: 0.5 }}
      >
        {formatBodyWeight(fatMass)}
      </TableCell>
      <TableCell
        align="right"
        sx={{ p: 0.5, color: fatFreeMassDiffColor }}
      >
        {fatFreeMassDiffSign}
        {formatBodyWeight(fatFreeMassDiff)}
      </TableCell>
      <TableCell
        align="right"
        sx={{ p: 0.5, color: fatMassDiffColor }}
      >
        {fatMassDiffSign}
        {formatBodyWeight(fatMassDiff)}
      </TableCell>
    </TableRow>
  )
}

export default MonthlyBodyWeightTableRow
