import { MyFitAppApi } from '@apis/MyFitApp'
import { useGetQuery } from '@hooks'

export const useGetWorkoutTypes = (type = null) => {
  const { data: workoutTypes = [], ...rest } = useGetQuery(
    ['workoutTypes', type],
    ({ accessToken }) => {
      return MyFitAppApi.getWorkoutTypes({ accessToken, type })
    },
    {
      staleTime: Infinity,
    }
  )

  return {
    workoutTypes,
    ...rest,
  }
}
