import { Button, CardContent, Text } from '../../../../components'
import { Row } from '../../../../containers'
import useCurrentDateTime from '../../../../hooks/useCurrentDateTime/useCurrentDateTime'
import { formatDuration } from '@utils/format'

export const OpenWorkoutCardContent = ({
  workout,
  isLoading,
  onJoinWorkout,
  onStopWorkout,
}) => {
  const currentDatetime = useCurrentDateTime()

  const workoutDuration = formatDuration(
    new Date(workout.startDate),
    currentDatetime
  )

  const handleStopWorkout = () => {
    if (window.confirm('do yout really want to stop the workout?')) {
      onStopWorkout(workout)
    }
  }

  const handleJoinWorkout = () => {
    onJoinWorkout(workout)
  }

  return (
    <CardContent
      aria-label="Open Workout Card"
      isLoading={isLoading}
    >
      <Row sx={{ justifyContent: 'space-between', mb: 1 }}>
        <Text>{workout.name}</Text>
        <Text>{workoutDuration}</Text>
      </Row>
      <Row sx={{ justifyContent: 'space-between' }}>
        <Text variant="caption">
          Locker: {workout?.lockerNumber ? workout?.lockerNumber : '-'}
        </Text>
        <Row sx={{ justifyContent: 'flex-end' }}>
          <Button
            aria-label="Join Workout"
            size="small"
            onClick={handleJoinWorkout}
            sx={{ mr: 1 }}
          >
            Join
          </Button>
          <Button
            aria-label="Stop Workout"
            size="small"
            color="secondary"
            onClick={handleStopWorkout}
          >
            Stop
          </Button>
        </Row>
      </Row>
    </CardContent>
  )
}
