import { Alert } from '@mui/material'
import { createContext, useContext, useState, useEffect } from 'react'
import useAlert from '../../hooks/useAlert/useAlert'

const AlertContext = createContext()

const useAlertContextValue = () => {
  const [data, setData] = useState(null)

  return {
    data,
    setData,
  }
}

const Notification = () => {
  const { visible, duration, color, text, close } = useAlert()

  useEffect(() => {
    if (visible) {
      const timeoutId = setTimeout(() => {
        close()
      }, duration)
      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [visible, duration, close])

  return (
    visible && (
      <Alert
        sx={{ position: 'absolute', bottom: 20, left: 20, right: 20 }}
        variant="filled"
        severity={color}
        onClose={close}
      >
        {text}
      </Alert>
    )
  )
}

export const AlertProvider = ({ children }) => {
  const value = useAlertContextValue()
  return (
    <AlertContext.Provider value={value}>
      {children}
      <Notification />
    </AlertContext.Provider>
  )
}

export const useAlertContext = () => {
  const context = useContext(AlertContext)

  if (!context) throw new Error('AlertContext must be inside a AlertProvider')

  return context
}
