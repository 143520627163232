import { Card, CardTitle } from '@components'
import { useGetWorkoutByDate, useGetWorkoutTypes } from '@modules/Workout/hooks'
import {
  StartWorkoutCardContent,
  OpenWorkoutCardContent,
  CreateWorkoutCardContent,
} from '@modules/Workout/containers'
import { useDateContext } from '@contexts'

export const WorkoutManagerCard = () => {
  const { isToday, isoDate } = useDateContext()
  const { isLoading, hasOpenWorkouts, currentOpenWorkout } =
    useGetWorkoutByDate({
      date: isoDate,
    })
  const { workoutTypes } = useGetWorkoutTypes()

  return (
    <Card>
      <CardTitle>Workout</CardTitle>
      {hasOpenWorkouts ? (
        <OpenWorkoutCardContent
          isLoading={isLoading}
          workout={currentOpenWorkout}
        />
      ) : isToday ? (
        <StartWorkoutCardContent
          isLoading={isLoading}
          workoutTypes={workoutTypes}
        />
      ) : (
        <CreateWorkoutCardContent
          isLoading={isLoading}
          workoutTypes={workoutTypes}
        />
      )}
    </Card>
  )
}
