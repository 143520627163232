import { useTab } from '@hooks'
import { MuscleTabs } from '@modules/Muscles/containers'

export const withMuscleTabs = (Component) => (props) => {
  const [muscleId, tab, setTab] = useTab(1)

  return (
    <>
      <MuscleTabs
        onChange={setTab}
        value={tab}
      />
      <Component
        muscleId={muscleId}
        {...props}
      />
    </>
  )
}
