import WeeklyBodyWeightCardComponent from '../../components/WeeklyBodyWeightCard/WeeklyBodyWeightCard'
import useWeeklyBodyWeights from '../../hooks/useWeeklyBodyWeights/useWeeklyBodyWeights'

const WeeklyBodyWeightCard = ({ startDate, endDate }) => {
  const { bodyWeights, weekAverage, loading } = useWeeklyBodyWeights({
    startDate,
    endDate,
  })

  return (
    <WeeklyBodyWeightCardComponent
      loading={loading}
      bodyWeights={bodyWeights}
      weekAverage={weekAverage}
    />
  )
}

export default WeeklyBodyWeightCard
