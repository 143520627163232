import { fetchApi } from '../../utils'

export const createGym = async ({ accessToken, name }) => {
  await fetchApi(`/gyms`, {
    method: 'POST',
    accessToken,
    body: {
      name,
    },
  })
}
