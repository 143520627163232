import { MoodDialog as MoodDialogComponent } from '@modules/Mood/components'
import { useCreateMood, useGetMoods } from '@modules/Mood/hooks'
import { getCurrentDate } from '@utils/date'

export const MoodDialog = () => {
  const date = getCurrentDate()
  const { moods, refetch, initialized } = useGetMoods({ date })
  const { createMood } = useCreateMood()

  const handleCreateMood = async (data) => {
    await createMood({ ...data, date })
    refetch()
  }

  const open = initialized && moods.length !== 1 && new Date().getHours() >= 6

  return (
    <MoodDialogComponent
      open={open}
      onSubmit={handleCreateMood}
    />
  )
}
