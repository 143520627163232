import { GoBackPage } from '@containers'
import { GymExercisesList } from '@modules/Gyms/containers'
import { useParams } from 'react-router-dom'

const GymExercisesPage = () => {
  const params = useParams()

  const gymId = parseInt(params.gymId)

  return (
    <GoBackPage title="Gym Exercises">
      <GymExercisesList gymId={gymId} />
    </GoBackPage>
  )
}

export default GymExercisesPage
