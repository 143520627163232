import { useState } from 'react'
import useInterval from '../useInterval/useInterval'

const useCurrentDateTime = () => {
  const [currentDatetime, setCurrentDatetime] = useState(new Date())

  useInterval(() => {
    setCurrentDatetime(new Date())
  }, 1000)

  return currentDatetime
}

export default useCurrentDateTime
