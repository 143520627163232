import { ListItem, ListItemText, ListItemThumbnail } from '@components'
import { ExerciseTypes } from '@modules/Exercises/types'
import { Star, StarBorder } from '@mui/icons-material'
import { IconButton, ListItemSecondaryAction } from '@mui/material'

export const GymExerciseListItem = ({ exercise, onAdd, onRemove }) => {
  const { type, name, images, active } = exercise

  const handleToggleGym = () => {
    if (active) {
      onRemove(exercise)
    } else {
      onAdd(exercise)
    }
  }

  return (
    <ListItem>
      <ListItemThumbnail images={images} />
      <ListItemText
        primary={name}
        secondary={ExerciseTypes[type]?.label}
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={handleToggleGym}
          color={active ? 'primary' : 'default'}
        >
          {active ? <Star /> : <StarBorder />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
