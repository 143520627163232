import { useCallback } from 'react'
import { useAlertContext } from '@contexts'

const DEFAULT_DURATION = 3000

const useAlert = (config = { duration: DEFAULT_DURATION }) => {
  const { duration = DEFAULT_DURATION } = config
  const { setData, data } = useAlertContext()

  const showAlert = useCallback(
    ({ text, color, onClose }) => {
      setData({
        text,
        color,
        duration,
        onClose,
      })
    },
    [duration, setData]
  )

  const close = () => {
    const { onClose } = data
    setData(null)
    if (onClose) {
      onClose()
    }
  }

  const showSuccess = useCallback(
    ({ text, onClose }) => showAlert({ text, onClose, color: 'success' }),
    [showAlert]
  )
  const showError = useCallback(
    ({ text, onClose }) => showAlert({ text, onClose, color: 'error' }),
    [showAlert]
  )

  return {
    showSuccess,
    showError,
    close,
    visible: data !== null,
    text: data?.text,
    color: data?.color,
    duration: data?.duration,
  }
}

export default useAlert
