import { GoBackPage } from '@containers'
import { CreateGymFab, GymsList } from '@modules/Gyms/containers'
import { useNavigate } from 'react-router-dom'

const GymsPage = () => {
  const navigate = useNavigate()

  const handleClickGym = ({ id }) => {
    navigate(`/gyms/${id}/exercises`)
  }

  return (
    <GoBackPage title="Gyms">
      <GymsList onClickGym={handleClickGym} />
      <CreateGymFab />
    </GoBackPage>
  )
}

export default GymsPage
