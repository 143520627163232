import { FormDialog } from '../../../../components'
import { BodyFatForm } from './components'

const BodyFatDialog = ({ title, open, onClose, onConfirm }) => {
  return (
    <FormDialog
      open={open}
      title={title}
      aria-label="Body Fat Dialog"
      onClose={onClose}
      onConfirm={onConfirm}
    >
      {(formProps) => <BodyFatForm {...formProps} />}
    </FormDialog>
  )
}

export default BodyFatDialog
