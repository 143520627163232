import { Column, Row } from '@containers'
import { forwardRef, useState } from 'react'

export const ScrollContainer = forwardRef(
  ({ children, onScrollBottom, sx, horizontal = false }, ref) => {
    const [isScrolledBottom, setIsScrolledBottom] = useState(false)

    const handleVerticalScrollToTheEnd = (e) => {
      const totalHeight = e.currentTarget.scrollHeight
      const currentBottom =
        e.currentTarget.offsetHeight + e.currentTarget.scrollTop
      const isBottom = currentBottom > totalHeight * 0.8
      if (isBottom !== isScrolledBottom) {
        setIsScrolledBottom(isBottom)
        if (isBottom && onScrollBottom) {
          onScrollBottom()
        }
      }
    }

    const handleScrollToTheEnd = (e) => {
      if (horizontal) {
      } else {
        handleVerticalScrollToTheEnd(e)
      }
    }

    return horizontal ? (
      <Row
        ref={ref}
        className="scrollable"
        sx={{
          overflowX: 'scroll',
          p: 1,
          pt: 0,
          width: '100%',
          alignItems: 'stretch',
          ...sx,
        }}
        onScroll={(e) => handleScrollToTheEnd(e)}
      >
        {children}
      </Row>
    ) : (
      <Column
        ref={ref}
        className="scrollable"
        sx={{ overflowY: 'scroll', p: 1, pt: 0, height: '100%', ...sx }}
        onScroll={(e) => handleScrollToTheEnd(e)}
      >
        {children}
      </Column>
    )
  }
)
