import Text from '../Text/Text'

const CardTitle = ({ children, variant = 'caption', ...props }) => {
  return (
    <Text
      variant={variant}
      role="heading"
      {...props}
    >
      {children}
    </Text>
  )
}

export default CardTitle
