import { formatToIsoDate } from '@utils/date'

const getApiUri = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 'http://localhost:8000'
    case 'beta':
      return 'https://beta.api.myfitapp.hosting163530.a2f21.netcup.net'
    case 'production':
      return 'https://api.myfitapp.hosting163530.a2f21.netcup.net'
    default:
      throw new Error('APP_ENV not found')
  }
}

const formatParam = (param) => {
  if ((param, param instanceof Date)) return formatToIsoDate(param)
  return param
}

const URI = getApiUri()

export const fetchApi = async (
  endpoint,
  config = {
    accessToken: null,
    signal: null,
    body: null,
    method: 'GET',
    params: {},
  }
) => {
  const {
    headers,
    method = 'GET',
    params = {},
    signal = null,
    accessToken,
    body,
  } = config
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  }
  if (body) {
    options.body = JSON.stringify(body)
  }
  if (signal) {
    options.signal = signal
  }
  const queryParams = Object.keys(params)
    .filter((key) => params[key])
    .map((key) => key + '=' + formatParam(params[key]))
    .join('&')
  if (accessToken) {
    options.headers['Authorization'] = `Bearer ${accessToken}`
  }
  const res = await fetch(
    `${URI}${endpoint}${queryParams ? '?' + queryParams : ''}`,
    options
  )
  if (res.status !== 200) throw new Error(res.statusText)
  return await res.json()
}

export const mockRequest = (data, duration = 1500) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(data)
    }, duration)
  })
