import { List } from '@components'
import { GymListItem } from '@modules/Gyms/components'

export const GymsList = ({
  gyms,
  onDeleteGym,
  isLoading,
  onUpdateGym,
  onUpdateHomeGym,
  onClickGym,
}) => {
  const handleToggleHomeGym = (homeGymId) => {
    onUpdateHomeGym({ homeGymId })
  }

  return (
    <List
      isLoading={isLoading}
      items={gyms}
      renderListItem={(gym) => (
        <GymListItem
          key={gym.id}
          gym={gym}
          onClick={onClickGym}
          onDelete={onDeleteGym}
          onUpdate={onUpdateGym}
          onToggleHomeGym={handleToggleHomeGym}
        />
      )}
    />
  )
}
