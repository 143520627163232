import { useUpdateHomeGym } from '@modules/Users/hooks'

export const withUpdateHomeGym =
  (Component) =>
  ({ isLoading = false, ...props }) => {
    const updateHomeGymApi = useUpdateHomeGym()

    return (
      <Component
        isLoading={isLoading || updateHomeGymApi.isLoading}
        onUpdateHomeGym={updateHomeGymApi.updateHomeGym}
        {...props}
      />
    )
  }
