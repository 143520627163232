import { fetchApi } from '../utils'

export const login = async ({ email, password }) => {
  const { accessToken } = await fetchApi('/auth/login', {
    method: 'POST',
    body: {
      email,
      password,
    },
  })
  return accessToken
}

export const signUp = async ({
  email,
  password,
  firstname,
  lastname,
  inviteKey,
}) => {
  await fetchApi('/auth/signup', {
    method: 'POST',
    body: {
      email,
      password,
      firstname,
      lastname,
      inviteKey,
    },
  })
}

export const forgotPassword = async ({ email }) => {
  await fetchApi('/auth/forgotPassword', {
    method: 'POST',
    body: {
      email,
    },
  })
}

export const resetPassword = async ({ resetToken, password }) => {
  await fetchApi('/auth/resetPassword', {
    method: 'PUT',
    body: {
      resetToken,
      password,
    },
  })
}
