import { useEffect } from 'react'

const useAsyncEffect = (asyncFunc) => {
  useEffect(() => {
    const controller = new AbortController()
    asyncFunc(controller.signal)
    return () => {
      controller.abort()
    }
  }, [asyncFunc])
}

export default useAsyncEffect
