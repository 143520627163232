import { useMediaQuery, useTheme } from '@mui/material'

const DEVICES = {
  DESKTOP: 'DESKTOP',
  TABLET: 'TABLET',
  SMARTPHONE: 'SMARTPHONE',
}

export const useMediaSize = () => {
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.up('sm'))
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const deviceSize = isLarge
    ? DEVICES.DESKTOP
    : isMedium
    ? DEVICES.TABLET
    : DEVICES.SMARTPHONE

  const isDesktop = deviceSize === DEVICES.DESKTOP
  const isTablet = deviceSize === DEVICES.TABLET
  const isSmartphone = deviceSize === DEVICES.SMARTPHONE

  return {
    deviceSize,
    isDesktop,
    isTablet,
    isSmartphone,
  }
}
