import dateApi from 'date-and-time'

export const isSameDate = (date1, date2) => {
  return formatToIsoDate(date1) === formatToIsoDate(date2)
}

export const calcWeek = (date) => {
  const dayOfWeek = date.getDay()
  const startDate = dateApi.addDays(date, -1 * ((dayOfWeek + 6) % 7))
  const endDate = dateApi.addDays(startDate, 6)
  return [startDate, endDate]
}

export const formatDateForInput = (
  date = new Date(),
  config = { short: false }
) => {
  const { short = false } = config
  const pattern = short ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss'
  return dateApi.format(date, pattern)
}

export const formatToIsoDate = (date = new Date()) => {
  const pattern = dateApi.compile('YYYY-MM-DD')

  if (typeof date === 'string') {
    date = new Date(date)
  }

  return dateApi.format(date, pattern)
}

export const formatToIsoDatetime = (date = new Date()) => {
  return date.toISOString()
}

export const getCurrentDate = () => {
  return formatDateForInput(new Date(), { short: true })
}

export const getLastDays = (lastDays = 7) => {
  const currentDate = new Date()
  const days = []
  for (let i = 1; i <= lastDays; i++) {
    days.push(dateApi.addDays(currentDate, -1 * (lastDays - i)))
  }
  return days
}
