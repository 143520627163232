import { GymExercisesList as GymExercisesListComponent } from '@modules/Gyms/components'
import {
  withGetGymExercises,
  withAddGymExercise,
  withRemoveGymExercise,
} from '@modules/Gyms/hocs'
import { withMuscleTabs } from '@modules/Muscles/hocs'

export const GymExercisesList = withMuscleTabs(
  withGetGymExercises(
    withAddGymExercise(withRemoveGymExercise(GymExercisesListComponent))
  )
)
