import { fetchApi } from '../utils'

// --- CREATE ------------

export const createExercises = async ({ accessToken, ...body }) => {
  const { exercise } = await fetchApi(`/exercises`, {
    method: 'POST',
    accessToken,
    body,
  })
  return exercise
}

export const saveExerciseImage = async ({ accessToken, exerciseId, image }) => {
  const { id, src } = await fetchApi(`/exercises/${exerciseId}/images`, {
    method: 'POST',
    accessToken,
    body: {
      exerciseId,
      image,
    },
  })
  return { id, src }
}

// --- READ   ------------

export const getExercises = async ({
  accessToken,
  muscleId,
  limit,
  page,
  workoutTypeId,
  archived,
  type,
  movement,
}) => {
  const { exercises } = await fetchApi(`/exercises`, {
    accessToken,
    params: {
      muscleId,
      limit,
      page,
      workoutTypeId,
      archived,
      type,
      movement,
    },
  })
  return exercises
}

// --- UPDATE ------------

export const updateExercise = async ({ accessToken, id, ...body }) => {
  const { exercise } = await fetchApi(`/exercises/${id}`, {
    method: 'PUT',
    accessToken,
    body,
  })
  return exercise
}

// --- DELETE ------------

export const deleteExercise = async ({ exerciseId, accessToken }) => {
  return await fetchApi(`/exercises/${exerciseId}`, {
    accessToken,
    method: 'DELETE',
  })
}

export const deleteExerciseImage = async ({ accessToken, imageId }) => {
  await fetchApi(`/exercises/images`, {
    method: 'DELETE',
    accessToken,
    params: {
      imageId,
    },
  })
}
