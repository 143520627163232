import { FormDialog } from '../../../../components'
import { BodyWeightForm } from './components'

const BodyWeightDialog = ({ title, open, onClose, onConfirm }) => {
  return (
    <FormDialog
      open={open}
      title={title}
      aria-label="Body Weight Dialog"
      onClose={onClose}
      onConfirm={onConfirm}
    >
      {(formProps) => <BodyWeightForm {...formProps} />}
    </FormDialog>
  )
}

export default BodyWeightDialog
