import { CircularProgress } from '@mui/material'
import { Row } from '../../containers'

const withCircularProgress =
  (Component, circularProgressProps) =>
  ({ loading = false, ...props }) => {
    return loading ? (
      <Row sx={{ justifyContent: 'center' }}>
        <CircularProgress {...circularProgressProps} />
      </Row>
    ) : (
      <Component {...props} />
    )
  }

export default withCircularProgress
