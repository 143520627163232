import { Select } from '@components'
import { MenuItem } from '@mui/material'

export const TrainingPlanSelect = ({
  control,
  trainingPlans,
  rules,
  helperText,
  label = 'Training Plan',
  id = 'training-plan-select',
  name = 'trainingPlanId',
  ...props
}) => {
  return (
    <Select
      id={id}
      label={label}
      name={name}
      control={control}
      rules={rules}
      helperText={helperText}
      {...props}
    >
      {trainingPlans.map(({ id, name }) => {
        return (
          <MenuItem
            key={id}
            value={id}
          >
            {name}
          </MenuItem>
        )
      })}
    </Select>
  )
}
