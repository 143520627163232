import { Typography } from '@mui/material'

const Text = ({ sx, children, ...props }) => {
  return (
    <Typography
      sx={{ color: 'text.primary', userSelect: 'none', ...sx }}
      {...props}
    >
      {children}
    </Typography>
  )
}

export default Text
