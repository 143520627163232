import { MyFitAppApi } from '@apis/MyFitApp'
import { useGetQuery } from '@hooks'

export const useGetGymExercises = ({ gymId, muscleId }) => {
  const { data: exercises = [], ...rest } = useGetQuery(
    ['gymExercises', gymId, muscleId],
    ({ accessToken }) =>
      MyFitAppApi.getGymExercises({ accessToken, gymId, muscleId })
  )

  return {
    exercises,
    ...rest,
  }
}
