import { fetchApi } from '../../utils'

export const createMood = async ({ accessToken, ...body }) => {
  const { mood } = await fetchApi(`/moods`, {
    method: 'POST',
    accessToken,
    body,
  })
  return mood
}
