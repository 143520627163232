import useDate from '@hooks/useDate/useDate'
import useWeek from '@hooks/useWeek/useWeek'
import { formatToIsoDate, getLastDays, isSameDate } from '@utils/date'
import { createContext, useContext } from 'react'

const DateContext = createContext()

const defaultLastDays = getLastDays()

const useDateContextValue = () => {
  const { date, increaseDate, decreaseDate, setDate } = useDate()
  const [startDate, endDate] = useWeek(date)

  const lastDays = {
    days: defaultLastDays,
    startDate: formatToIsoDate(defaultLastDays[0]),
    endDate: formatToIsoDate(defaultLastDays[defaultLastDays.length - 1]),
  }

  const week = {
    startDate: formatToIsoDate(startDate),
    endDate: formatToIsoDate(endDate),
  }

  const isoDate = formatToIsoDate(date)

  const isToday = isSameDate(date, new Date())

  return {
    date,
    lastDays,
    week,
    startDate,
    endDate,
    isoDate,
    isToday,
    increaseDate,
    decreaseDate,
    setDate,
  }
}

export const DateProvider = ({ children }) => {
  const value = useDateContextValue()
  return <DateContext.Provider value={value}>{children}</DateContext.Provider>
}

export const useDateContext = () => {
  const context = useContext(DateContext)

  if (!context) throw new Error('DateContext must be inside a DateProvider')

  return context
}
