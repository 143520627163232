import { fetchApi } from '../utils'

// --- CREATE ------------
// --- READ   ------------
// --- UPDATE ------------
// --- DELETE ------------

export const deleteImage = async ({ accessToken, imageId }) => {
  await fetchApi(`/images/${imageId}`, {
    method: 'DELETE',
    accessToken,
  })
}
