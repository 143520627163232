export const decodeBase64 = (str) => {
  return decodeURIComponent(window.atob(str))
}

export const getAccessTokenPayload = (accessToken) => {
  if (typeof accessToken !== 'string') return null

  const chunks = accessToken.split('.')

  if (chunks.length !== 3) throw new Error('invalid accessToken')

  try {
    return JSON.parse(decodeBase64(chunks[1]))
  } catch (error) {
    return null
  }
}

export const isSharedToken = (accessToken) => {
  const payload = getAccessTokenPayload(accessToken)
  if (payload === null) return false

  return payload?.topic === 'SHARE'
}

export const isAccessTokenValid = (accessToken) => {
  const payload = getAccessTokenPayload(accessToken)

  if (typeof payload?.userId !== 'number') return false
  if (typeof payload?.topic !== 'string') return false

  return true
}
