import { MoreVert } from '@mui/icons-material'
import { ClickAwayListener, IconButton, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'

export const MenuIconButton = ({ options, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (onClick) => () => {
    onClick()
    handleClose()
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
      </ClickAwayListener>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map(({ label, onClick }, i) => (
          <MenuItem
            key={i}
            onClick={handleChange(onClick)}
            dense
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
