import { useDeleteGymExercise } from '@modules/Gyms/hooks'

export const withRemoveGymExercise =
  (Component) =>
  ({ isLoading = false, ...props }) => {
    const { gymId, muscleId = null } = props

    const deleteGymExerciseApi = useDeleteGymExercise({ gymId, muscleId })

    return (
      <Component
        isLoading={isLoading || deleteGymExerciseApi.isLoading}
        onRemoveGymExercise={deleteGymExerciseApi.deleteGymExercise}
        {...props}
      />
    )
  }
