import { MyFitAppApi } from '@apis/MyFitApp'
import { usePostQuery } from '@hooks'

export const useUpdateGym = () => {
  const { mutate: updateGym, ...rest } = usePostQuery(
    ({ accessToken, ...data }) =>
      MyFitAppApi.updateGym({ accessToken, ...data }),
    {
      invalidateQueries: () => [['gyms']],
    }
  )

  return {
    updateGym,
    ...rest,
  }
}
