import { Link as RouterLink } from 'react-router-dom'

const Link = (props) => {
  return (
    <RouterLink
      style={{ color: '#fff' }}
      {...props}
    />
  )
}

export default Link
