import { GymSelect } from '@modules/Gyms/containers'
import {
  TrainingPlanDaySelect,
  TrainingPlanSelect,
} from '@modules/TrainingPlans/containers'
import { FormControlLabel, Switch } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, TextField, ToggleTabs } from '../../../../components'
import { Column } from '../../../../containers'
import WorkoutTypesSelect from '../WorkoutTypesSelect/WorkoutTypesSelect'

const StartWorkoutForm = ({ onSubmit, workoutTypes }) => {
  const [type, setType] = useState('WEIGHT_TRAINING')
  const [isTrainingPlanWorkout, setIsTrainingPlanWorkout] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm()

  const workoutTypeId = watch('workoutTypeId')
  const trainingPlanId = watch('trainingPlanId')

  const handleChangeIsTrainingPlanWorkout = () => {
    setIsTrainingPlanWorkout((value) => !value)
  }

  useEffect(() => {
    if (workoutTypeId) {
      const id = parseInt(workoutTypeId)
      const workoutType = workoutTypes.find((t) => t.id === id)
      if (workoutType) {
        setValue('name', workoutType.name)
      }
    }
  }, [workoutTypeId, workoutTypes, setValue])

  const filteredWorkoutTypes = useMemo(
    () => workoutTypes.filter((workoutType) => workoutType.type === type),
    [workoutTypes, type]
  )

  return (
    <Column
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ p: 1, pt: 0, flex: 1 }}
    >
      {/* <TextField
        label="Name"
        helperText={errors?.name?.message}
        inputProps={{
          "aria-label": "Name",
        }}
        {...register("name", {
          required: "name required",
        })}
      /> */}
      <TextField
        label="Locker Number"
        helperText={errors?.lockerNumber?.message}
        inputProps={{
          'aria-label': 'Locker Number',
        }}
        {...register('lockerNumber')}
      />
      <ToggleTabs
        width="40%"
        margin="normal"
        color="secondary"
        items={[
          {
            label: 'Weight Training',
            value: 'WEIGHT_TRAINING',
          },
          {
            label: 'Cardio',
            value: 'CARDIO',
          },
          {
            label: 'Others',
            value: 'OTHERS',
          },
        ]}
        value={type}
        onChange={setType}
      />
      {type === 'WEIGHT_TRAINING' && (
        <FormControlLabel
          control={
            <Switch
              sx={{ mt: 2, mb: 1, ml: 1 }}
              checked={isTrainingPlanWorkout}
              onChange={handleChangeIsTrainingPlanWorkout}
            />
          }
          label="with Training Plan"
        />
      )}
      {isTrainingPlanWorkout && type === 'WEIGHT_TRAINING' && (
        <>
          <TrainingPlanSelect control={control} />
          <TrainingPlanDaySelect
            trainingPlanId={trainingPlanId}
            control={control}
          />
        </>
      )}
      {(!isTrainingPlanWorkout || type !== 'WEIGHT_TRAINING') && (
        <WorkoutTypesSelect
          control={control}
          rules={{
            required: 'workout type required',
          }}
          helperText={errors?.workoutTypeId?.message}
          workoutTypes={filteredWorkoutTypes}
        />
      )}
      {type !== 'OTHERS' && (
        <GymSelect
          control={control}
          rules={{
            required: 'Gym required',
          }}
          helperText={errors?.gymId?.message}
        />
      )}
      <Button
        type="submit"
        aria-label="Start Workout"
        sx={{ mt: 2, mb: 1 }}
      >
        Start
      </Button>
    </Column>
  )
}

export default StartWorkoutForm
