import { useCreateGym } from '@modules/Gyms/hooks'

export const withCreateGym =
  (Component) =>
  ({ isLoading = false, ...props }) => {
    const createGymApi = useCreateGym()

    return (
      <Component
        isLoading={isLoading || createGymApi.isLoading}
        onCreate={createGymApi.createGym}
        {...props}
      />
    )
  }
