import { MyFitAppApi } from '@apis/MyFitApp'
import { useGetQuery } from '@hooks'

export const useGetWorkouts = ({ startDate = null, endDate = null }) => {
  const {
    data = {
      openWorkouts: [],
      closedWorkouts: [],
    },
    ...result
  } = useGetQuery(['workouts', startDate, endDate], async ({ accessToken }) => {
    const { openWorkouts, closedWorkouts } = await MyFitAppApi.getWorkouts({
      accessToken,
      startDate,
      endDate,
    })
    return {
      openWorkouts,
      closedWorkouts,
    }
  })

  return {
    ...data,
    ...result,
  }
}
