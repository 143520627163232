import { Row } from '../../containers'
import Button from '../Button/Button'

const ToggleTabs = ({
  items,
  width = '25%',
  color = 'primary',
  onChange,
  value,
  margin = 'none',
}) => {
  const containerSx = margin === 'normal' ? { mt: 2, mb: 1 } : {}

  return (
    <Row
      sx={{
        justifyContent: 'space-around',
        alignItems: 'stretch',
        mb: 1,
        ...containerSx,
      }}
    >
      {items.map((item) => {
        const selected = value === item.value
        return (
          <Button
            key={item.value}
            role="tab"
            aria-label={`${item.label} Tab`}
            aria-selected={selected}
            size="small"
            color={color}
            onClick={() => onChange(item.value)}
            sx={{ width, opacity: selected ? 1 : 0.5 }}
          >
            {item.label}
          </Button>
        )
      })}
    </Row>
  )
}

export default ToggleTabs
