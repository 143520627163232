import { MyFitAppApi } from '@apis/MyFitApp'
import { useAccessTokenContext } from '@contexts'
import useAlert from '@hooks/useAlert/useAlert'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useDeleteTrainingPlan = () => {
  const [accessToken] = useAccessTokenContext()
  const queryClient = useQueryClient()
  const { showSuccess, showError } = useAlert()

  const { mutate: deleteTrainingPlan, ...rest } = useMutation({
    mutationFn: (trainingPlanId) => {
      return MyFitAppApi.deleteTrainingPlan({ accessToken, trainingPlanId })
    },
    onSuccess: () => {
      showSuccess({ text: 'training plan deleted successfully!' })
      queryClient.invalidateQueries({ queryKey: ['trainingPlans'] })
    },
    onError: () => {
      showError({ text: 'training plan  could not be deleted!' })
    },
  })

  return {
    ...rest,
    deleteTrainingPlan,
  }
}
