import { TextField as MaterialTextField } from '@mui/material'
import { forwardRef } from 'react'

const TextField = forwardRef(({ helperText, inputProps, ...props }, ref) => {
  return (
    <MaterialTextField
      ref={ref}
      margin="normal"
      inputProps={{ role: 'textbox', ...inputProps }}
      error={typeof helperText === 'string'}
      helperText={helperText}
      {...props}
    />
  )
})

export default TextField
