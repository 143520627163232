const THEME_COLORS = {
  INDIGO: {
    id: 'INDIGO',
    label: 'Indigo',
    colors: {
      main: '#3f51b5',
      light: '#757de8',
      dark: '#002984',
    },
  },
  PINK: {
    id: 'PINK',
    label: 'Pink',
    colors: {
      main: '#e91e63',
      light: '#ff6090',
      dark: '#b0003a',
    },
  },
  YELLOW: {
    id: 'YELLOW',
    label: 'Yellow',
    colors: {
      main: '#ffeb3b',
      light: '#ffff72',
      dark: '#c8b900',
    },
  },
  GREEN: {
    id: 'GREEN',
    label: 'Green',
    colors: {
      main: '#4caf50',
      light: '#80e27e',
      dark: '#087f23',
    },
  },
  ORANGE: {
    id: 'ORANGE',
    label: 'Orange',
    colors: {
      main: '#ff9800',
      light: '#ffc947',
      dark: '#c66900',
    },
  },
}

export default THEME_COLORS
