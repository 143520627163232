import { MyFitAppApi } from '@apis/MyFitApp'
import { usePostQuery } from '@hooks'

export const useShareWorkout = () => {
  const { mutate: shareWorkout, ...rest } = usePostQuery(
    ({ accessToken, id }) => MyFitAppApi.shareWorkout({ accessToken, id }),
    {
      onSuccess: (url, { name, startDate }) => {
        try {
          navigator.share({
            url,
            title: `${startDate}: ${name}`,
            text: 'Here is my Workout:\n',
          })
        } catch (error) {
          alert(typeof navigator.share)
          alert(error.message)
        }
      },
    }
  )

  return { shareWorkout, ...rest }
}
