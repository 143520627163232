import { useState } from 'react'

const useDialog = (defaultValue = false) => {
  const [open, setOpen] = useState(defaultValue)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return [open, handleOpen, handleClose]
}

export default useDialog
