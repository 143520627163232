import { useQuery } from '@tanstack/react-query'
import { formatToIsoDate } from '@utils/date'
import { MyFitAppApi } from '../../../../apis/MyFitApp'
import { useAccessTokenContext } from '@contexts'

const useWeeklyWorkload = ({ startDate, endDate }) => {
  const [accessToken] = useAccessTokenContext()

  const isoStartDate = formatToIsoDate(startDate)
  const isoEndDate = formatToIsoDate(endDate)

  const {
    data: weeklyWorkload = [],
    isLoading,
    error,
  } = useQuery(['weeklyWorkload', isoStartDate, isoEndDate], () =>
    MyFitAppApi.getWeeklyWorkload({
      accessToken,
      startDate: isoStartDate,
      endDate: isoEndDate,
    })
  )

  return {
    error,
    isLoading,
    weeklyWorkload,
  }
}

export default useWeeklyWorkload
