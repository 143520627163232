import { fetchApi } from '../../utils'

export const getMoods = async ({ accessToken, date }) => {
  const { moods } = await fetchApi(`/moods`, {
    method: 'GET',
    accessToken,
    params: {
      date,
    },
  })
  return moods
}
