import { fetchApi } from '../utils'

// --- CREATE ------------

export const createFood = async ({ accessToken, ...body }) => {
  const { food } = await fetchApi(`/foods`, {
    method: 'POST',
    accessToken,
    body,
  })
  return food
}

export const saveFoodImage = async ({ accessToken, foodId, imageData }) => {
  const { image } = await fetchApi(`/foods/images`, {
    method: 'POST',
    accessToken,
    body: {
      foodId,
      imageData,
    },
  })
  return image
}

// --- READ   ------------

export const getFoods = async ({ accessToken }) => {
  const { foods } = await fetchApi(`/foods`, {
    accessToken,
  })
  return foods
}

// --- UPDATE ------------
// --- DELETE ------------

export const deleteFood = async ({ accessToken, foodId }) => {
  await fetchApi(`/foods/${foodId}`, {
    method: 'DELETE',
    accessToken,
  })
}
