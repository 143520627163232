import { createContext, useContext } from 'react'
import { useStoredState } from '../../hooks/use-stored-state.hook'
import THEME_COLORS from '../../types/theme-colors'

const BACKROUND_CARD = {
  DARK: 'rgba(255, 255, 255, 0.05)',
  LIGHT: 'rgba(0, 0, 0, 0.05)',
}

const defaultTheme = {
  palette: {
    mode: 'dark',
    primary: THEME_COLORS.INDIGO.colors,
    secondary: THEME_COLORS.PINK.colors,
    background: {
      card: BACKROUND_CARD.DARK,
      chip: 'rgba(255, 255, 255, 0.1)',
    },
  },
}

const ThemeContext = createContext()

const useThemeContextValue = () => {
  const [theme, setTheme] = useStoredState('theme', {
    defaultValue: defaultTheme,
  })

  const toggleDarkMode = () => {
    setTheme({
      ...theme,
      palette: {
        ...theme?.palette,
        mode: theme?.palette?.mode === 'dark' ? 'light' : 'dark',
        background: {
          ...theme?.palette?.background,
          card:
            theme?.palette?.mode === 'dark'
              ? BACKROUND_CARD.LIGHT
              : BACKROUND_CARD.DARK,
        },
      },
    })
  }

  const setPrimaryColor = (color) => {
    setTheme({
      ...theme,
      palette: {
        ...theme?.palette,
        primary: color,
      },
    })
  }

  const setSecondaryColor = (color) => {
    setTheme({
      ...theme,
      palette: {
        ...theme?.palette,
        secondary: color,
      },
    })
  }

  return {
    theme,
    toggleDarkMode,
    setPrimaryColor,
    setSecondaryColor,
  }
}

export const ThemeProvider = ({ children }) => {
  const value = useThemeContextValue()
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export const useThemeContext = () => {
  const context = useContext(ThemeContext)

  if (!context) throw new Error('ThemeContext must be inside a ThemeProvider')

  const { theme, toggleDarkMode, setPrimaryColor, setSecondaryColor } = context

  return { theme, toggleDarkMode, setPrimaryColor, setSecondaryColor }
}
