import { fetchApi } from '../utils.js'

// --- CREATE ------------

export const createBodyFat = async ({ accessToken, ...body }) => {
  const { bodyFat } = await fetchApi(`/bodyFats`, {
    method: 'POST',
    accessToken,
    body,
  })
  return bodyFat
}

// --- READ   ------------

export const getBodyFats = async ({
  accessToken,
  date,
  startDate,
  endDate,
  signal,
}) => {
  const { bodyFats } = await fetchApi(`/bodyFats`, {
    accessToken,
    signal,
    params: {
      date,
      startDate,
      endDate,
    },
  })
  return bodyFats
}

// --- DELETE   ------------

export const deleteBodyFat = async ({ bodyFatId, accessToken }) => {
  return await fetchApi(`/bodyFats/${bodyFatId}`, {
    accessToken,
    method: 'DELETE',
  })
}
