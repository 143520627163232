import { MyFitAppApi } from '@apis/MyFitApp'
import { useAccessTokenContext } from '@contexts'
import { useQuery } from '@tanstack/react-query'

export const useGetTrainingPlans = () => {
  const [accessToken] = useAccessTokenContext()

  const {
    data: trainingPlans = [],
    isError,
    isLoading,
  } = useQuery(['trainingPlans'], () =>
    MyFitAppApi.getTrainingPlans({ accessToken })
  )

  return {
    trainingPlans,
    isError,
    isLoading,
  }
}
