import { Row } from '@containers'
import { Text } from '@components'

const NumberBadge = ({ children }) => {
  return (
    <Row
      sx={{
        height: 20,
        width: 20,
        borderRadius: '50%',
        p: 1,
        bgcolor: 'background.chip',
        justifyContent: 'center',
      }}
    >
      <Text align="center">{children}</Text>
    </Row>
  )
}

export default NumberBadge
