import { fetchApi } from '../../utils'

export const updateGym = async ({ accessToken, id, name }) => {
  await fetchApi(`/gyms/${id}`, {
    method: 'PUT',
    accessToken,
    body: {
      name,
    },
  })
}
