import * as AuthApi from './auth/auth.api'
import * as BodyFatsApi from './bodyFats/bodyFats.api'
import * as BodyWeightsApi from './bodyWeights/bodyWeights.api'
import * as ExercisesApi from './exercises/exercises.api'
import * as FoodsApi from './foods/foods.api'
import * as ImagesApi from './images/images.api'
import * as MusclesApi from './muscles/muscles.api'
import * as WorkoutsApi from './workouts/workouts.api'
import * as WorkoutSetsApi from './workoutSets/workoutSets.api'
import * as UsersApi from './users/users.api'
import * as MoodsApi from './moods'
import * as TrendsApi from './trends'
import * as TrainingPlansApi from './trainingPlans'
import * as GymsApi from './gyms'

export const MyFitAppApi = {
  ...AuthApi,
  ...BodyFatsApi,
  ...BodyWeightsApi,
  ...ExercisesApi,
  ...FoodsApi,
  ...ImagesApi,
  ...MusclesApi,
  ...WorkoutsApi,
  ...WorkoutSetsApi,
  ...UsersApi,
  ...MoodsApi,
  ...TrendsApi,
  ...TrainingPlansApi,
  ...GymsApi,
}
