import { Delete } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Dialog } from '../../../../components'
import Row from '../../../Row/Row'

const ImageDialog = ({ image: item, onClose, onDelete, deletable }) => {
  const image = item?.images?.length > 0 ? item.images[0] : null

  const handleDeleteImage = () => {
    if (onDelete) {
      onDelete({
        id: item.id,
        imageId: image?.id,
      })
    }
    if (onClose) {
      onClose()
    }
  }

  const open = item !== null

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      {deletable && (
        <Row sx={{ justifyContent: 'flex-end' }}>
          <IconButton onClick={handleDeleteImage}>
            <Delete />
          </IconButton>
        </Row>
      )}
      <img
        alt={'big representation in dialog'}
        title={'big representation in dialog'}
        src={image?.src}
        width="100%"
        height="auto"
      />
    </Dialog>
  )
}

export default ImageDialog
