import { List } from '@mui/material'
import ListPageItem from '../ListPageItem/ListPageItem'

const ListPageList = ({ items, transform, ...props }) => {
  return (
    <List>
      {items.map((item) => {
        const {
          key,
          image,
          name = '',
          primary = '',
          secondary = '',
          count = 0,
        } = transform(item)
        return (
          <ListPageItem
            key={key}
            image={image}
            name={name}
            primary={primary}
            secondary={secondary}
            item={item}
            count={count}
            {...props}
          />
        )
      })}
    </List>
  )
}

export default ListPageList
