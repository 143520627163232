import { useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAccessTokenContext } from '@contexts'
import { isAccessTokenValid } from '@utils/token'

const PrivateRoute = ({ children }) => {
  const [accessToken] = useAccessTokenContext()
  const location = useLocation()

  const isValidAccessToken = useMemo(() => {
    return isAccessTokenValid(accessToken)
  }, [accessToken])

  return isValidAccessToken ? (
    children
  ) : (
    <Navigate
      to="/login"
      state={{ from: location }}
      replace
    />
  )
}

export default PrivateRoute
