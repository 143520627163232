import { withProgress } from '@components'
import { TrainingPlanDaySelect as TrainingPlanDaySelectComponent } from '@modules/TrainingPlans/components'
import { withGetTrainingPlans } from '@modules/TrainingPlans/hocs'

export const TrainingPlanDaySelect = withGetTrainingPlans(
  withProgress(
    ({ control, trainingPlans, trainingPlanId, rules, helperText }) => {
      const trainingPlan = trainingPlans.find(
        (trainingPlan) => trainingPlan.id === trainingPlanId
      ) || { days: [] }

      return (
        <TrainingPlanDaySelectComponent
          control={control}
          trainingPlanDays={trainingPlan.days}
          rules={rules}
          helperText={helperText}
        />
      )
    }
  )
)
