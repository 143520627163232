import { useCallback, useState } from 'react'
import { MyFitAppApi } from '../../../../apis/MyFitApp'
import { useAccessTokenContext } from '@contexts'

const useBodyWeights = () => {
  const [accessToken] = useAccessTokenContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [bodyWeights, setBodyWeights] = useState(null)

  const getBodyWeights = useCallback(
    async ({ startDate, endDate }) => {
      setLoading(true)
      try {
        const bodyWeights = await MyFitAppApi.getBodyWeights({
          accessToken,
          startDate: startDate.toISOString().substring(0, 10),
          endDate: endDate.toISOString().substring(0, 10),
        })
        setBodyWeights(bodyWeights)
      } catch (error) {
        setError('failed to get body weights')
      }
      setLoading(false)
    },
    [accessToken]
  )

  return {
    loading,
    error,
    bodyWeights,
    getBodyWeights,
  }
}

export default useBodyWeights
