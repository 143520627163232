import { useNavigate } from 'react-router-dom'
import { IconButton } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'
import { Row } from '..'
import { Text } from '../../components'
import HeaderPage from '../HeaderPage/HeaderPage'

const GoBackPage = ({ title, children, beforeTitle, right, isLoading }) => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <HeaderPage
      left={
        <Row>
          <IconButton
            aria-label="Go Back"
            onClick={handleGoBack}
          >
            <ChevronLeft />
          </IconButton>
          {beforeTitle}
          {title && <Text sx={{ ml: 1 }}>{title}</Text>}
        </Row>
      }
      right={right}
      isLoading={isLoading}
    >
      {children}
    </HeaderPage>
  )
}

export default GoBackPage
