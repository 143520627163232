import { fetchApi } from '../utils'

// --- CREATE ------------

export const startWorkout = async ({
  accessToken,
  name,
  startDate,
  workoutTypeId,
  lockerNumber,
  gymId,
  trainingPlanDayId,
}) => {
  const { workout } = await fetchApi('/workouts/start', {
    accessToken,
    method: 'POST',
    body: {
      name,
      startDate,
      workoutTypeId,
      lockerNumber,
      gymId,
      trainingPlanDayId,
    },
  })
  return workout
}

export const createWorkout = async ({
  accessToken,
  name,
  workoutTypeId,
  startDate,
  endDate,
}) => {
  const { workout } = await fetchApi('/workouts', {
    accessToken,
    method: 'POST',
    body: { name, workoutTypeId, startDate, endDate },
  })
  return workout
}

export const shareWorkout = async ({ accessToken, id }) => {
  const { url } = await fetchApi(`/workouts/${id}/share`, {
    accessToken,
    method: 'POST',
  })
  return url
}

// --- READ   ------------

export const getWorkouts = async ({
  accessToken,
  date,
  startDate,
  endDate,
}) => {
  const { openWorkouts, closedWorkouts } = await fetchApi('/workouts', {
    accessToken,
    params: { date, startDate, endDate },
  })
  return { openWorkouts, closedWorkouts }
}

export const getWorkout = async ({ accessToken, id }) => {
  const { workout } = await fetchApi(`/workouts/${id}`, {
    accessToken,
  })
  return workout
}

export const getWorkoutTypes = async ({ accessToken, type }) => {
  const { workoutTypes } = await fetchApi('/workouts/types', {
    accessToken,
    params: {
      type,
    },
  })
  return workoutTypes
}

export const getWorkoutExercises = async ({ accessToken, workoutId }) => {
  const { exercises } = await fetchApi(`/workouts/${workoutId}/exercises`, {
    accessToken,
  })
  return exercises
}

// --- UPDATE ------------

export const stopWorkout = async ({ workoutId, endDate, accessToken }) => {
  const { workout } = await fetchApi('/workouts/stop', {
    accessToken,
    method: 'POST',
    body: { workoutId, endDate },
  })
  return workout
}

export const updateWorkout = async ({
  id,
  name,
  workoutTypeId,
  startDate,
  endDate,
  accessToken,
}) => {
  const { workout } = await fetchApi(`/workouts/${id}`, {
    accessToken,
    method: 'PUT',
    body: { name, workoutTypeId, startDate, endDate },
  })
  return workout
}

// --- DELETE ------------

export const deleteWorkout = async ({ workoutId, accessToken }) => {
  return await fetchApi(`/workouts/${workoutId}`, {
    accessToken,
    method: 'DELETE',
  })
}
