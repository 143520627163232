import { MyFitAppApi } from '@apis/MyFitApp'
import { usePostQuery } from '@hooks'

export const useDeleteGymExercise = ({ gymId, muscleId }) => {
  const { mutate: deleteGymExercise, ...rest } = usePostQuery(
    ({ accessToken, id }) =>
      MyFitAppApi.deleteGymExercise({ accessToken, gymId, exerciseId: id }),
    {
      invalidateQueries: () => [['gymExercises', gymId, muscleId]],
    }
  )

  return {
    deleteGymExercise,
    ...rest,
  }
}
