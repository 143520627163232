import { Photo } from '@mui/icons-material'
import { Avatar, ListItemAvatar } from '@mui/material'

export const ListItemThumbnail = ({ images }) => {
  const thumbnail = images?.length > 0 ? images[0] : null

  return (
    <ListItemAvatar sx={{ color: 'text.primary' }}>
      {thumbnail ? <Avatar src={thumbnail.src} /> : <Photo />}
    </ListItemAvatar>
  )
}
