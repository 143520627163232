import { useGetWorkouts } from '@modules/Workout/hooks/useGetWorkouts/useGetWorkouts'
import { LinearProgress } from '@mui/material'
import { formatToIsoDate, getLastDays, isSameDate } from '@utils/date'
import { useMemo } from 'react'
import { LastWorkoutCards as LastWorkoutCardsComponent } from '@modules/Workout/components'

const lastDays = getLastDays()

export const LastWorkoutCards = ({ date, onSelect }) => {
  const startDate = formatToIsoDate(lastDays[0])
  const endDate = formatToIsoDate(lastDays[lastDays.length - 1])

  const { isLoading, openWorkouts, closedWorkouts } = useGetWorkouts({
    startDate,
    endDate,
  })

  const lastWorkoutDays = useMemo(() => {
    return lastDays.map((date) => {
      const workout = [...openWorkouts, ...closedWorkouts].find((w) =>
        isSameDate(new Date(w.startDate), date)
      )
      return {
        date,
        workout,
      }
    })
  }, [openWorkouts, closedWorkouts])

  return isLoading ? (
    <LinearProgress />
  ) : (
    <LastWorkoutCardsComponent
      date={date}
      lastWorkoutDays={lastWorkoutDays}
      onSelect={onSelect}
    />
  )
}
