import Resizer from 'react-image-file-resizer'
import imageCompression from 'browser-image-compression'
import { lazy } from 'react'

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      64,
      64,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'base64'
    )
  })

export const trimBase64 = (str) => {
  const searchString = 'base64,'
  const pos = str.search(searchString)
  return str.substring(pos + searchString.length, str.length)
}

export const convertToBase64 = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(file)
  })

export const compressImage = async (image) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 600,
    useWebWorker: true,
  }
  return await imageCompression(image, options)
}

export const lazyLoadPage = (page) => {
  return lazy(() => import(`../pages/${page}/${page}`))
}
