import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Route, Routes } from 'react-router-dom'
import { PrivateRoute } from './containers'
import withProviders from './Providers'
import ROUTES from './routes'

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        {ROUTES.public.map(({ path, page: Page }) => (
          <Route
            key={path}
            path={path}
            element={<Page />}
          />
        ))}
        {ROUTES.private.map(({ path, page: Page }) => (
          <Route
            key={path}
            path={path}
            element={
              <PrivateRoute>
                <Page />
              </PrivateRoute>
            }
          />
        ))}
      </Routes>
    </QueryClientProvider>
  )
}

export default withProviders(App)
