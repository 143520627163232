import { useCallback, useEffect, useState } from 'react'
import { MyFitAppApi } from '../../../../apis/MyFitApp'
import { useAccessTokenContext } from '@contexts'

const useBodyWeightStats = () => {
  const [accessToken] = useAccessTokenContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [bodyWeightStats, setBodyWeightStats] = useState(null)

  const getBodyWeightStats = useCallback(async () => {
    setLoading(true)
    try {
      const bodyWeightStats = await MyFitAppApi.getBodyWeightStats({
        accessToken,
      })
      setBodyWeightStats(bodyWeightStats)
    } catch (error) {
      setError('failed to get body weight stats')
    }
    setLoading(false)
  }, [accessToken])

  useEffect(() => getBodyWeightStats(), [getBodyWeightStats])

  return {
    loading,
    error,
    bodyWeightStats,
  }
}

export default useBodyWeightStats
