import { Button, Form, TextField } from '../../../../../../components'
import { useForm } from 'react-hook-form'

const BodyWeightForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const handleData = ({ weight, ...data }) => {
    onSubmit({
      ...data,
      weight: parseFloat(weight),
    })
  }

  return (
    <Form
      onSubmit={handleSubmit(handleData)}
      sx={{ p: 2, pt: 0 }}
    >
      <TextField
        label="Body Weight (kg)"
        type="number"
        inputProps={{
          step: 0.1,
          'aria-label': 'bodyWeight',
        }}
        autoFocus
        helperText={errors?.weight?.message}
        {...register('weight', {
          required: 'weight required',
        })}
      />
      <Button
        aria-label="Save Body Weight"
        type="submit"
        sx={{ mt: 2 }}
      >
        Save
      </Button>
    </Form>
  )
}

export default BodyWeightForm
