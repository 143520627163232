import { CircularProgress, LinearProgress } from '@mui/material'

export const withProgress =
  (Component, config = { type: 'linear' }) =>
  ({ isLoading = false, ...props }) => {
    const { type = 'linear', ...progressProps } = config

    const Progress = type === 'linear' ? LinearProgress : CircularProgress

    return isLoading ? (
      <Progress {...progressProps} />
    ) : (
      <Component {...props} />
    )
  }
