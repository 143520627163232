import { Card, CardTitle } from '../../../../components'
import { BodyWeightCardContent } from './components'

const BodyWeightCard = ({ loading, bodyWeight, onDelete, onCreate }) => {
  const handleDeleteBodyWeight = (bodyWeight) => {
    if (window.confirm('Do yout really want to delete the body weight?')) {
      onDelete(bodyWeight)
    }
  }

  return (
    <Card aria-label="Body Weight Card">
      <CardTitle aria-label="Body Weight">Body Weight</CardTitle>
      <BodyWeightCardContent
        loading={loading}
        bodyWeight={bodyWeight}
        onDelete={handleDeleteBodyWeight}
        onCreate={onCreate}
      />
    </Card>
  )
}

export default BodyWeightCard
