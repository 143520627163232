import { List } from '@components'
import { GymExerciseListItem } from '@modules/Gyms/components'

export const GymExercisesList = ({
  exercises,
  isLoading,
  onAddGymExercise,
  onRemoveGymExercise,
}) => {
  return (
    <List
      isLoading={isLoading}
      items={exercises}
      renderListItem={(exercise) => (
        <GymExerciseListItem
          key={exercise.id}
          exercise={exercise}
          onAdd={onAddGymExercise}
          onRemove={onRemoveGymExercise}
        />
      )}
    />
  )
}
