import { MenuItem } from '@mui/material'
import { Select } from '@components'

const WorkoutTypesSelect = ({
  control,
  workoutTypes,
  rules,
  helperText,
  label = 'Workout Type',
  id = 'workout-type',
  name = 'workoutTypeId',
  ...props
}) => {
  return (
    <Select
      id={id}
      label={label}
      name={name}
      control={control}
      rules={rules}
      helperText={helperText}
      {...props}
    >
      {workoutTypes.map(({ id, name }) => {
        return (
          <MenuItem
            key={id}
            value={id}
          >
            {name}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default WorkoutTypesSelect
