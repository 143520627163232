import { Card, CardTitle, Text } from '@components'
import { Column, Row, ScrollContainer } from '@containers'
import { useGetTrends } from '@modules/Trends/hooks'
import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material'
import { CircularProgress, Divider } from '@mui/material'
import {
  formatBodyFat,
  formatCalories,
  formatFFMI,
  formatHeartRateZone,
  formatWeight,
} from '@utils/format'
import { useMemo } from 'react'

export const TrendCards = ({ date }) => {
  const { hrv, moods, dailyActivities, body } = useGetTrends({ date })

  const trendCategories = useMemo(() => {
    return [
      {
        title: 'Recovery',
        data: {
          value: moods?.recoveryIndex?.current?.value,
          color:
            moods?.recoveryIndex?.current?.value > 70
              ? 'success'
              : moods?.recoveryIndex?.current?.value > 60
              ? 'warning'
              : 'error',
        },
        trend: {
          value: moods?.recoveryIndex?.trend,
          color:
            moods?.recoveryIndex?.trend === 'UP'
              ? 'success'
              : moods?.recoveryIndex?.trend === 'DOWN'
              ? 'error'
              : 'warning',
        },
        trends: [
          {
            label: 'Muscle Recovery',
            data: {
              value: moods?.muscleRecovery?.current?.value,
              color:
                moods?.muscleRecovery?.current?.value > 6
                  ? 'success.main'
                  : moods?.muscleRecovery?.current?.value > 4
                  ? 'warning.main'
                  : 'error.main',
            },
            trend: {
              value: moods?.muscleRecovery?.trend,
              color:
                moods?.muscleRecovery?.trend === 'UP'
                  ? 'success'
                  : moods?.muscleRecovery?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
          {
            label: 'Sleep',
            data: {
              value: moods?.sleep?.current?.value,
              color:
                moods?.sleep?.current?.value > 6
                  ? 'success.main'
                  : moods?.sleep?.current?.value > 4
                  ? 'warning.main'
                  : 'error.main',
            },
            trend: {
              value: moods?.sleep?.trend,
              color:
                moods?.sleep?.trend === 'UP'
                  ? 'success'
                  : moods?.sleep?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
          {
            label: 'Mood',
            data: {
              value: moods?.mood?.current?.value,
              color:
                moods?.mood?.current?.value > 6
                  ? 'success.main'
                  : moods?.mood?.current?.value > 4
                  ? 'warning.main'
                  : 'error.main',
            },
            trend: {
              value: moods?.mood?.trend,
              color:
                moods?.mood?.trend === 'UP'
                  ? 'success'
                  : moods?.mood?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
          {
            label: 'Motivation',
            data: {
              value: moods?.motivation?.current?.value,
              color:
                moods?.motivation?.current?.value > 6
                  ? 'success.main'
                  : moods?.motivation?.current?.value > 4
                  ? 'warning.main'
                  : 'error.main',
            },
            trend: {
              value: moods?.motivation?.trend,
              color:
                moods?.motivation?.trend === 'UP'
                  ? 'success'
                  : moods?.motivation?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
          {
            label: 'Heart Rate Variability (Daily)',
            data: {
              value: parseInt(hrv?.daily?.current?.value),
              color:
                hrv?.daily?.current?.value >= 100
                  ? 'success.main'
                  : hrv?.daily?.current?.value > 50
                  ? 'warning.main'
                  : 'error.main',
            },
            trend: {
              value: hrv?.daily?.trend,

              color:
                hrv?.daily?.trend === 'UP'
                  ? 'success'
                  : hrv?.daily?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
          {
            label: 'Heart Rate Variability (Sleep)',
            data: {
              value: parseInt(hrv?.sleep?.current?.value),
              color:
                hrv?.sleep?.current?.value >= 100
                  ? 'success.main'
                  : hrv?.sleep?.current?.value > 50
                  ? 'warning.main'
                  : 'error.main',
            },
            trend: {
              value: hrv?.sleep?.trend,

              color:
                hrv?.sleep?.trend === 'UP'
                  ? 'success'
                  : hrv?.sleep?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
          {
            label: 'Resting Heart Rate',
            data: {
              value: dailyActivities?.restingHeartRate?.current?.value,
              color:
                dailyActivities?.restingHeartRate?.current?.value < 50
                  ? 'warning.main'
                  : dailyActivities?.restingHeartRate?.current?.value <= 80
                  ? 'success.main'
                  : dailyActivities?.restingHeartRate?.current?.value < 90
                  ? 'warning.main'
                  : 'error.main',
            },
            trend: {
              value: dailyActivities?.restingHeartRate?.trend,
              color:
                dailyActivities?.restingHeartRate?.trend === 'UP'
                  ? 'error'
                  : dailyActivities?.restingHeartRate?.trend === 'DOWN'
                  ? 'success'
                  : 'warning',
            },
          },
        ],
      },
      {
        title: 'Body',
        data: {
          value: parseInt(body?.bodyIndex?.current?.value),
          color:
            body?.bodyIndex?.current?.value > 70
              ? 'success'
              : body?.v?.current?.value > 60
              ? 'warning'
              : 'error',
        },
        trend: {
          value: body?.bodyIndex?.trend,
          color:
            body?.bodyIndex?.trend === 'UP'
              ? 'success'
              : body?.bodyIndex?.trend === 'DOWN'
              ? 'error'
              : 'warning',
        },
        trends: [
          {
            label: 'Body Weight',
            data: {
              value: formatWeight(body?.bodyWeight?.current?.value),
              color: 'text.primary',
            },
            trend: {
              value: body?.bodyWeight?.trend,
              color: 'success',
            },
          },
          {
            label: 'Body Fat',
            data: {
              value: formatBodyFat(body?.bodyFat?.current?.value * 100),
              color:
                body?.bodyFat?.current?.value > 0.2
                  ? 'error.main'
                  : body?.bodyFat?.current?.value > 0.15
                  ? 'warning.main'
                  : 'success.main',
            },
            trend: {
              value: body?.bodyFat?.trend,
              color:
                body?.bodyFat?.trend === 'UP'
                  ? 'error'
                  : body?.bodyFat?.trend === 'DOWN'
                  ? 'success'
                  : 'warning',
            },
          },
          {
            label: 'Fat Free Mass Index',
            data: {
              value: formatFFMI(body?.fatFreeMassIndex?.current?.value),
              color:
                body?.fatFreeMassIndex?.current?.value > 6
                  ? 'success.main'
                  : body?.fatFreeMassIndex?.current?.value > 4
                  ? 'warning.main'
                  : 'error.main',
            },
            trend: {
              value: body?.fatFreeMassIndex?.trend,
              color:
                body?.fatFreeMassIndex?.trend === 'UP'
                  ? 'success'
                  : body?.fatFreeMassIndex?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
        ],
      },
      {
        title: 'Activity',
        data: {
          value: parseInt(dailyActivities?.activityIndex?.current?.value),
          color:
            dailyActivities?.activityIndex?.current?.value > 70
              ? 'success'
              : dailyActivities?.activityIndex?.current?.value > 60
              ? 'warning'
              : 'error',
        },
        trend: {
          value: dailyActivities?.activityIndex?.trend,
          color:
            dailyActivities?.activityIndex?.trend === 'UP'
              ? 'success'
              : dailyActivities?.activityIndex?.trend === 'DOWN'
              ? 'error'
              : 'warning',
        },
        trends: [
          {
            label: 'Steps',
            data: {
              value: dailyActivities?.steps?.current?.value,
              color:
                dailyActivities?.steps?.current?.value >= 10000
                  ? 'success.main'
                  : dailyActivities?.steps?.current?.value > 7000
                  ? 'warning.main'
                  : 'error.main',
            },
            trend: {
              value: dailyActivities?.steps?.trend,
              color:
                dailyActivities?.steps?.trend === 'UP'
                  ? 'success'
                  : dailyActivities?.steps?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
          {
            label: 'Total Calories Burned',
            data: {
              value: formatCalories(
                dailyActivities?.totalCalories?.current?.value
              ),
              color: 'text.primary',
            },
            trend: {
              value: dailyActivities?.totalCalories?.trend,
              color:
                dailyActivities?.totalCalories?.trend === 'UP'
                  ? 'success'
                  : dailyActivities?.totalCalories?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
          {
            label: 'Non Active Calories Burned',
            data: {
              value: formatCalories(
                dailyActivities?.nonActivityCalories?.current?.value
              ),
              color: 'text.primary',
            },
            trend: {
              value: dailyActivities?.nonActivityCalories?.trend,
              color:
                dailyActivities?.nonActivityCalories?.trend === 'UP'
                  ? 'success'
                  : dailyActivities?.nonActivityCalories?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
          {
            label: 'Active Calories Burned',
            data: {
              value: formatCalories(
                dailyActivities?.activityCalories?.current?.value
              ),
              color: 'text.primary',
            },
            trend: {
              value: dailyActivities?.activityCalories?.trend,
              color:
                dailyActivities?.activityCalories?.trend === 'UP'
                  ? 'success'
                  : dailyActivities?.activityCalories?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
          {
            label: 'Baseline Heart Rate',
            data: {
              value: formatHeartRateZone(
                dailyActivities?.heartRateZone1?.current?.value
              ),
              color:
                dailyActivities?.heartRateZone1?.current?.value < 60 * 20
                  ? 'success.main'
                  : dailyActivities?.heartRateZone1?.current?.value < 60 * 22
                  ? 'warning.main'
                  : 'success.main',
            },
            trend: {
              value: dailyActivities?.heartRate1?.trend,
              color:
                dailyActivities?.heartRate1?.trend === 'UP'
                  ? 'error'
                  : dailyActivities?.heartRate1?.trend === 'DOWN'
                  ? 'success'
                  : 'warning',
            },
          },
          {
            label: 'Fat Burn Heart Rate',
            data: {
              value: formatHeartRateZone(
                dailyActivities?.heartRateZone2?.current?.value
              ),
              color:
                dailyActivities?.heartRateZone2?.current?.value < 100
                  ? 'error.main'
                  : dailyActivities?.heartRateZone2?.current?.value < 60 * 3
                  ? 'warning.main'
                  : 'success.main',
            },
            trend: {
              value: dailyActivities?.heartRateZone2?.trend,
              color:
                dailyActivities?.heartRateZone2?.trend === 'UP'
                  ? 'success'
                  : dailyActivities?.heartRateZone2?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
          {
            label: 'Cardio Heart Rate',
            data: {
              value: formatHeartRateZone(
                dailyActivities?.heartRateZone3?.current?.value
              ),
              color:
                dailyActivities?.heartRateZone3?.current?.value > 0
                  ? 'success.main'
                  : 'warning.main',
            },
            trend: {
              value: dailyActivities?.heartRateZone3?.trend,
              color:
                dailyActivities?.heartRateZone3?.trend === 'UP'
                  ? 'success'
                  : dailyActivities?.heartRateZone3?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
          {
            label: 'Peak Heart Rate',
            data: {
              value: formatHeartRateZone(
                dailyActivities?.heartRateZone4?.current?.value
              ),
              color:
                dailyActivities?.heartRateZone4?.current?.value > 0
                  ? 'success.main'
                  : 'text.primary',
            },
            trend: {
              value: dailyActivities?.heartRateZone4?.trend,
              color:
                dailyActivities?.heartRateZone4?.trend === 'UP'
                  ? 'success'
                  : dailyActivities?.heartRateZone4?.trend === 'DOWN'
                  ? 'error'
                  : 'warning',
            },
          },
        ],
      },
    ]
  }, [hrv, moods, dailyActivities, body])

  return (
    <ScrollContainer>
      {trendCategories.map((props) => (
        <TrendCard
          key={props.title}
          {...props}
        />
      ))}
    </ScrollContainer>
  )
}

const TrendCard = ({ title, trend, data, trends }) => {
  return (
    <Card>
      <TrendCardTitle
        title={title}
        trend={trend}
        data={data}
      />
      <Divider sx={{ mt: 1, mb: 1 }} />
      <Column>
        {trends.map(({ label, data, trend }) => {
          return (
            <Row
              key={label}
              sx={{ justifyContent: 'space-between' }}
            >
              <Text variant="caption">{label}</Text>
              <Row>
                <Text
                  variant="caption"
                  sx={{ color: data.color, mr: 1 }}
                >
                  {data.value}
                </Text>
                <TrendIcon
                  trend={trend.value}
                  color={trend.color}
                />
              </Row>
            </Row>
          )
        })}
      </Column>
    </Card>
  )
}

const TrendCardTitle = ({ title, trend, data }) => {
  return (
    <Row sx={{ justifyContent: 'space-between' }}>
      <Row>
        <Column
          sx={{
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            border: '4px solid',
            borderColor: '#121212',
            borderRadius: '50%',
            width: 24,
            height: 24,
          }}
        >
          <CircularProgress
            variant="determinate"
            value={data.value}
            color={data.color}
            size={32}
            sx={{ position: 'absolute' }}
          />
          <Text
            variant="caption"
            align="center"
          >
            {data.value}
          </Text>
        </Column>
        <CardTitle
          variant="body1"
          sx={{ ml: 1.5 }}
        >
          {title}
        </CardTitle>
      </Row>
      <TrendIcon
        trend={trend.value}
        color={trend.color}
      />
    </Row>
  )
}

const TrendIcon = ({ trend, color }) => {
  return trend === 'UP' ? (
    <TrendingUp
      color={color}
      fontSize="medium"
    />
  ) : trend === 'DOWN' ? (
    <TrendingDown
      color={color}
      fontSize="medium"
    />
  ) : (
    <TrendingFlat
      color={color}
      fontSize="medium"
    />
  )
}
