import { Box } from '@mui/material'
import { forwardRef } from 'react'

const Column = forwardRef(({ sx, children, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  )
})

export default Column
