export const ExerciseTypes = {
  BODYWEIGHT: {
    id: 'BODYWEIGHT',
    label: 'Body Weight',
  },
  PLATE_LOADED: {
    id: 'PLATE_LOADED',
    label: 'Plate Loaded',
  },
  STACK_LOADED: {
    id: 'STACK_LOADED',
    label: 'Stack Loaded',
  },
}
