import { withProgress } from '@components'
import { GymSelect as GymSelectComponent } from '@modules/Gyms/components'
import { withGetGyms } from '@modules/Gyms/hocs'

export const GymSelect = withGetGyms(
  withProgress(({ helperText, rules, control, gyms }) => {
    return (
      <GymSelectComponent
        helperText={helperText}
        rules={rules}
        control={control}
        gyms={gyms}
      />
    )
  })
)
