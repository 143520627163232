import { useEditItem } from '@hooks'
import { GymDialog } from '@modules/Gyms/components'
import { useUpdateGym } from '@modules/Gyms/hooks'

export const withUpdateGym =
  (Component) =>
  ({ isLoading = false, ...props }) => {
    const editItemApi = useEditItem()
    const updateGymApi = useUpdateGym()

    return (
      <>
        <Component
          isLoading={isLoading || updateGymApi.isLoading}
          onUpdateGym={editItemApi.set}
          {...props}
        />
        <GymDialog
          isOpen={editItemApi.isOpen}
          isLoading={updateGymApi.isLoading}
          onClose={editItemApi.reset}
          onUpdate={updateGymApi.updateGym}
          defaultValues={editItemApi.item}
        />
      </>
    )
  }
