export const formatDate = (date, config) => {
  if (!(date instanceof Date)) return '-'
  return date.toLocaleDateString('de', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    ...config,
  })
}

export const formatWeekday = (date) => {
  if (!(date instanceof Date)) return '-'
  return date.toLocaleDateString('de', { weekday: 'long' })
}

export const formatBodyWeight = (weight) => {
  return (
    (weight
      ? Intl.NumberFormat('de', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(weight)
      : '-') + 'kg'
  )
}

export const formatFFMI = (value) => {
  return value
    ? Intl.NumberFormat('de', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(value)
    : '-'
}

export const formatDuration = (startDate, endDate) => {
  const milliseconds = endDate.getTime() - startDate.getTime()
  const hours = Math.floor(milliseconds / (1000 * 3600))
  const minutes = Math.floor((milliseconds - hours * 1000 * 3600) / (1000 * 60))
  const seconds = Math.floor(
    (milliseconds - hours * 1000 * 3600 - minutes * 1000 * 60) / 1000
  )
  return (
    (hours > 9 ? hours : hours < 0 ? '00' : '0' + hours) +
    ':' +
    (minutes > 9 ? minutes : minutes < 0 ? '00' : '0' + minutes) +
    ':' +
    (seconds > 9 ? seconds : seconds < 0 ? '00' : '0' + seconds)
  )
}

export const formatBodyFat = (bodyFat) => {
  return (
    (bodyFat
      ? Intl.NumberFormat('de', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(bodyFat)
      : '-') + '%'
  )
}

export const formatWeight = (weight) => {
  if (isNaN(weight)) return '-'
  return (
    Intl.NumberFormat('de', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(weight) + 'kg'
  )
}

export const formatCalories = (value) => {
  if (isNaN(value)) return '-'
  return (
    Intl.NumberFormat('de', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value) + ' kcal'
  )
}

export const formatHeartRateZone = (value) => {
  if (isNaN(value)) return '-'
  return (
    Intl.NumberFormat('de', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value) + ' Minutes'
  )
}

export const formatInt = (value) => {
  if (isNaN(value)) return '-'
  return Intl.NumberFormat('de', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value)
}
