import { ListItemText, MenuIconButton, ListItem } from '@components'
import { Star, StarBorder } from '@mui/icons-material'
import { IconButton, ListItemSecondaryAction } from '@mui/material'

export const GymListItem = ({
  gym,
  onDelete,
  onUpdate,
  onToggleHomeGym,
  onClick,
}) => {
  const handleSetAsDefault = () => {
    onToggleHomeGym(gym.isHomeGym ? null : gym.id)
  }

  const handleClick = () => {
    onClick(gym)
  }

  const handleUpdate = () => {
    onUpdate(gym)
  }

  const handleDelete = () => {
    if (window.confirm('Do you really want to delete the gym?')) {
      onDelete(gym)
    }
  }

  return (
    <ListItem
      onClick={handleClick}
      button
    >
      <ListItemText primary={gym.name} />
      <ListItemSecondaryAction>
        <IconButton
          color={gym.isHomeGym ? 'primary' : 'default'}
          onClick={handleSetAsDefault}
        >
          {gym.isHomeGym ? <Star /> : <StarBorder />}
        </IconButton>
        <MenuIconButton
          options={[
            {
              label: 'Edit',
              onClick: handleUpdate,
            },
            {
              label: 'Delete',
              onClick: handleDelete,
            },
          ]}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}
