import { MyFitAppApi } from '@apis/MyFitApp'
import { useGetQuery } from '@hooks'

export const useGetWorkoutByDate = ({ date }) => {
  const { data = { openWorkouts: [], closedWorkouts: [] }, ...result } =
    useGetQuery(['workouts', date], async ({ accessToken }) => {
      const { openWorkouts, closedWorkouts } = await MyFitAppApi.getWorkouts({
        accessToken,
        date,
      })
      return {
        openWorkouts,
        closedWorkouts,
      }
    })

  const { closedWorkouts, openWorkouts } = data

  const hasOpenWorkouts = openWorkouts?.length > 0
  const hasClosedWorkouts = closedWorkouts?.length > 0
  const hasWorkouts = hasOpenWorkouts || hasClosedWorkouts
  const currentOpenWorkout = hasOpenWorkouts ? openWorkouts[0] : null

  return {
    hasOpenWorkouts,
    hasClosedWorkouts,
    hasWorkouts,
    currentOpenWorkout,
    openWorkouts,
    closedWorkouts,
    ...result,
  }
}
