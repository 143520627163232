import { withProgress } from '@components'
import { Tab, Tabs } from '@mui/material'

export const MuscleTabs = withProgress(({ muscles, onChange, value }) => {
  const handleChange = (_, value) => {
    console.log('value', value)
    onChange(value)
  }
  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      value={value}
      onChange={handleChange}
    >
      {muscles.map((muscle) => (
        <Tab
          key={muscle.id}
          label={muscle.name}
          value={muscle.id}
        />
      ))}
    </Tabs>
  )
})
