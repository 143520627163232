import { useAccessTokenContext } from '@contexts'
import { useGetQuery } from '@hooks'
import { formatToIsoDate } from '@utils/date'
import { MyFitAppApi } from '../../../../apis/MyFitApp'

export const useGetTrends = ({ date }) => {
  const [accessToken] = useAccessTokenContext()
  const { data, ...result } = useGetQuery(['trends', date], () =>
    MyFitAppApi.getTrends({
      accessToken,
      endDate: formatToIsoDate(date),
    })
  )

  const { moods, dailyActivities, hrv, body } = data || {}

  return {
    ...result,
    moods,
    dailyActivities,
    hrv,
    body,
  }
}
