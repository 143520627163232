import { MyFitAppApi } from '@apis/MyFitApp'
import { useDateContext } from '@contexts'
import { usePostQuery } from '@hooks/index'
import { formatToIsoDatetime } from '@utils/date'

export const useStopWorkout = () => {
  const { isoDate, lastDays } = useDateContext()

  const { mutate: stopWorkout, ...rest } = usePostQuery(
    ({ accessToken, id }) => {
      const endDate = formatToIsoDatetime()
      return MyFitAppApi.stopWorkout({
        accessToken,
        workoutId: id,
        endDate,
      })
    },
    {
      invalidateQueries: ({ id }) => [
        ['workouts', isoDate],
        ['workouts', id],
        ['workouts', lastDays.startDate, lastDays.endDate],
      ],
    }
  )

  return {
    stopWorkout,
    ...rest,
  }
}
