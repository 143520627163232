import { CircularProgress } from '@mui/material'
import { Row } from '../../containers'
import Button from '../Button/Button'

const PendingButton = ({
  pending = false,
  margin = 'none',
  progressProps,
  children,
  ...props
}) => {
  const containerSx = margin === 'normal' ? { mt: 2, mb: 1 } : {}

  return (
    <Row sx={{ justifyContent: 'center', ...containerSx }}>
      {pending ? (
        <CircularProgress {...progressProps} />
      ) : (
        <Button {...props}>{children}</Button>
      )}
    </Row>
  )
}

export default PendingButton
