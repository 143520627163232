import { CircularProgress, LinearProgress } from '@mui/material'
import { Text } from '../../../../components'
import { Column, Row } from '../../../../containers'
import useWeeklyWorkload from '../../hooks/useWeeklyWorkload/useWeeklyWorkload'

const MuscleWorkloadCard = ({
  startDate,
  endDate,
  minValue = 10,
  maxValue = 20,
}) => {
  const { weeklyWorkload, isLoading } = useWeeklyWorkload({
    startDate,
    endDate,
  })

  return (
    <Column
      component="article"
      aria-label="Muscle Workload Card"
      sx={{
        mt: 1,
        borderRadius: 5,
        boxShadow: 6,
        p: 2,
        bgcolor: 'background.card',
      }}
    >
      <Text variant="caption">Muscle Workload</Text>
      {isLoading ? (
        <Row sx={{ justifyContent: 'center' }}>
          <CircularProgress />
        </Row>
      ) : (
        weeklyWorkload && (
          <>
            {weeklyWorkload.map((muscle, i) => {
              const { name, sets } = muscle

              const color =
                sets < minValue
                  ? 'error'
                  : sets <= maxValue
                  ? 'success'
                  : 'warning'

              const value = (sets % 10) * 10

              return (
                <Column
                  key={i}
                  sx={{ mb: 1 }}
                >
                  <Row sx={{ justifyContent: 'space-between', mb: 0.5 }}>
                    <Text>{name}</Text>
                    <Text>{sets} Sets</Text>
                  </Row>
                  <LinearProgress
                    color={color}
                    variant="buffer"
                    value={value}
                  />
                </Column>
              )
            })}
          </>
        )
      )}
    </Column>
  )
}

export default MuscleWorkloadCard
