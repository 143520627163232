import { Card, CardTitle } from '@components'
import { useDateContext } from '@contexts'
import { WorkoutHistoryCardContent } from '@modules/Workout/components'
import {
  useGetWorkoutByDate,
  useGetWorkoutTypes,
  useShareWorkout,
  useUpdateWorkout,
} from '@modules/Workout/hooks'
import { useDeleteWorkout } from '@modules/Workout/hooks/useDeleteWorkout/useDeleteWorkout'
import { useNavigate } from 'react-router-dom'

export const WorkoutHistoryCard = () => {
  const navigate = useNavigate()
  const { isoDate } = useDateContext()
  const deleteWorkoutApi = useDeleteWorkout()
  const updateWorkoutApi = useUpdateWorkout()
  const shareWorkoutApi = useShareWorkout()
  const getWorkoutApi = useGetWorkoutByDate({
    date: isoDate,
  })
  const { workoutTypes } = useGetWorkoutTypes()

  const handleJoinWorkout = (workout) => {
    navigate(`/workouts/${workout.id}`, { state: { workout } })
  }

  const handleDeleteWorkout = (workout) => {
    if (window.confirm('do you really want to delete the workout?')) {
      deleteWorkoutApi.deleteWorkout(workout)
    }
  }

  const isLoading =
    getWorkoutApi.isLoading ||
    deleteWorkoutApi.isLoading ||
    updateWorkoutApi.isLoading

  const isVisible =
    getWorkoutApi.fetchStatus === 'fetching' ||
    (getWorkoutApi.status === 'success' && getWorkoutApi.hasClosedWorkouts)

  return (
    isVisible && (
      <Card>
        <CardTitle>Workouts</CardTitle>
        <WorkoutHistoryCardContent
          isLoading={isLoading}
          workoutTypes={workoutTypes}
          closedWorkouts={getWorkoutApi.closedWorkouts}
          onJoinWorkout={handleJoinWorkout}
          onDeleteWorkout={handleDeleteWorkout}
          onEditWorkout={updateWorkoutApi.updateWorkout}
          onShareWorkout={shareWorkoutApi.shareWorkout}
        />
      </Card>
    )
  )
}
