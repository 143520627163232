import { ScrollContainer } from '@containers'
import { LinearProgress, List as MaterialList } from '@mui/material'

export const List = ({ isLoading, renderListItem, items }) => {
  return (
    <ScrollContainer>
      {isLoading && <LinearProgress />}
      <MaterialList>{items.map(renderListItem)}</MaterialList>
    </ScrollContainer>
  )
}
