import { useMuscles } from '@modules/Muscles/hooks'
import { MuscleTabs as MuscleTabsComponent } from '@modules/Muscles/components'

export const MuscleTabs = ({ value, onChange }) => {
  const { muscles, isLoading } = useMuscles()
  return (
    <MuscleTabsComponent
      muscles={muscles}
      isLoading={isLoading}
      onChange={onChange}
      value={value}
    />
  )
}
