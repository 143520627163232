import { ListItemText, ListItemThumbnail } from '@components'
import { ExerciseTypes } from '@modules/Exercises/types'
import { ListItem } from '@mui/material'

export const ExerciseListItem = ({ exercise, onClick }) => {
  const { type, name, images } = exercise

  const handleClick = (e) => {
    onClick(exercise)
  }

  return (
    <ListItem
      button
      dense
      onClick={handleClick}
    >
      <ListItemThumbnail images={images} />
      <ListItemText
        primary={name}
        secondary={ExerciseTypes[type]?.label}
      />
    </ListItem>
  )
}
