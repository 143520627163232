import { MyFitAppApi } from '@apis/MyFitApp'
import { useDateContext } from '@contexts'
import { usePostQuery } from '@hooks/index'

export const useUpdateWorkout = () => {
  const { isoDate, lastDays } = useDateContext()

  const { mutate: updateWorkout, ...rest } = usePostQuery(
    ({ accessToken, id, name, type, startDate, endDate }) => {
      return MyFitAppApi.updateWorkout({
        accessToken,
        id,
        name,
        workoutTypeId: type.id,
        startDate,
        endDate,
      })
    },
    {
      invalidateQueries: () => [
        ['workouts', isoDate],
        ['workouts', lastDays.startDate, lastDays.endDate],
      ],
    }
  )

  return {
    updateWorkout,
    ...rest,
  }
}
