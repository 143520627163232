import { useGetTrainingPlans } from '@modules/TrainingPlans/hooks'

export const withGetTrainingPlans =
  (Component) =>
  ({ isLoading = false, ...props }) => {
    const getTrainingPlansApi = useGetTrainingPlans()

    return (
      <Component
        isLoading={isLoading || getTrainingPlansApi.isLoading}
        trainingPlans={getTrainingPlansApi.trainingPlans}
        {...props}
      />
    )
  }
