import { Dialog } from '@components'
import { LinearProgress } from '@mui/material'

export const withFormDialog =
  (Component) =>
  ({
    isOpen,
    isLoading = false,
    title,
    onCreate,
    onUpdate,
    onClose,
    ...props
  }) => {
    const handleSubmit = (data) => {
      if (data.id && onUpdate) {
        onUpdate(data)
      } else if (onCreate) {
        onCreate(data)
      }
      onClose()
    }

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        title={title}
      >
        {isLoading && <LinearProgress />}
        <Component
          onSubmit={handleSubmit}
          {...props}
        />
      </Dialog>
    )
  }
