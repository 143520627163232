import { useCallback, useState } from 'react'
import { MyFitAppApi } from '../../../../apis/MyFitApp'
import { useAccessTokenContext } from '@contexts'
import useAsyncEffect from '../../../../hooks/useAsyncEffect/useAsyncEffect'

const useBodyFat = ({ date }) => {
  const [accessToken] = useAccessTokenContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [bodyFat, setBodyFat] = useState(null)

  const deleteBodyFat = async (bodyFat) => {
    setLoading(true)
    try {
      await MyFitAppApi.deleteBodyFat({
        accessToken,
        bodyFatId: bodyFat.id,
      })
      setBodyFat(null)
    } catch (error) {
      setError('failed to delete body fat')
    }
    setLoading(false)
  }

  const createBodyFat = async (data) => {
    setLoading(true)
    try {
      const bodyFat = await MyFitAppApi.createBodyFat({
        accessToken,
        ...data,
        date: date.toISOString().substring(0, 10),
      })
      setBodyFat(bodyFat)
    } catch (error) {
      setError('failed to create body fat')
    }
    setLoading(false)
  }

  const getBodyFat = useCallback(
    async (signal) => {
      setLoading(true)
      try {
        const bodyFats = await MyFitAppApi.getBodyFats({
          accessToken,
          signal,
          date: date.toISOString().substring(0, 10),
        })
        if (bodyFats.length > 0) {
          setBodyFat(bodyFats[0])
        } else {
          setBodyFat(null)
        }
      } catch (error) {
        setError('failed to get body fat')
      }
      setLoading(false)
    },
    [accessToken, date]
  )

  useAsyncEffect(getBodyFat)

  return {
    loading,
    error,
    bodyFat,
    createBodyFat,
    deleteBodyFat,
    getBodyFat,
  }
}

export default useBodyFat
