import { forwardRef } from 'react'
import { TextField } from '..'

const EmailField = forwardRef(({ label = 'Email', ...props }, ref) => {
  return (
    <TextField
      ref={ref}
      type="email"
      label={label}
      {...props}
    />
  )
})

export default EmailField
