import { createContext, useContext } from 'react'
import { useStoredState } from '../../hooks/use-stored-state.hook'

const UserContext = createContext()

const useUserContextValue = () => {
  const [user, setUser] = useStoredState('user', { defaultValue: {} })

  return {
    user,
    setUser,
  }
}

export const UserProvider = ({ children }) => {
  const value = useUserContextValue()
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  const context = useContext(UserContext)

  if (!context) throw new Error('UserContext must be inside a UserProvider')

  const { user: userData, setUser } = context

  const {
    user = null,
    friends = [],
    requestedFriends = [],
    friendRequests = [],
  } = userData

  return {
    user,
    friends,
    friendRequests,
    requestedFriends,
    setUser,
  }
}
