import { useState } from 'react'

export const useEditItem = () => {
  const [item, setItem] = useState(null)

  const reset = () => {
    setItem(null)
  }

  const isOpen = Boolean(item)

  return {
    isOpen,
    item,
    set: setItem,
    reset,
  }
}
