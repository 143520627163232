import { Button, Form } from '@components'
import { Column } from '@containers'
import { Slider, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

const defaultValues = {
  sleep: 5,
  mood: 5,
  motivation: 5,
  soreness: 5,
}

export const MoodForm = ({ onSubmit }) => {
  const { handleSubmit, control } = useForm({ defaultValues })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <SliderInput
        control={control}
        name="sleep"
        label="Sleep"
        marks={[
          {
            value: 0,
            label: 'Bad',
          },
          {
            value: 10,
            label: 'Good',
          },
        ]}
      />
      <SliderInput
        control={control}
        name="mood"
        label="Mood"
        marks={[
          {
            value: 0,
            label: 'Bad',
          },
          {
            value: 10,
            label: 'Good',
          },
        ]}
      />
      <SliderInput
        control={control}
        name="motivation"
        label="Motivation"
        marks={[
          {
            value: 0,
            label: 'Low',
          },
          {
            value: 10,
            label: 'High',
          },
        ]}
      />
      <SliderInput
        control={control}
        name="soreness"
        label="Soreness"
        marks={[
          {
            value: 0,
            label: 'Low',
          },
          {
            value: 10,
            label: 'High',
          },
        ]}
      />
      <Button
        type="submit"
        sx={{ mt: 2 }}
      >
        SAVE
      </Button>
    </Form>
  )
}

const SliderInput = ({ control, name, label, marks }) => {
  return (
    <Column sx={{ mt: 2, mb: 1 }}>
      <Typography gutterBottom>{label}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Slider
            {...field}
            marks={marks}
            steps={1}
            min={0}
            max={10}
          />
        )}
      />
    </Column>
  )
}
