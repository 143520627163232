import { useGetQuery } from '@hooks'
import { MyFitAppApi } from '../../../../apis/MyFitApp'

export const useGetExercises = (config = { muscleId: null }) => {
  const { muscleId = null } = config

  const { data: exercises = [], ...result } = useGetQuery(
    ['exercises', muscleId],
    ({ accessToken }) => MyFitAppApi.getExercises({ accessToken, muscleId })
  )

  return {
    exercises,
    ...result,
  }
}
