import { useAccessTokenContext } from '@contexts'
import useAlert from '@hooks/useAlert/useAlert'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const usePostQuery = (
  queryFunc,
  config = {
    onSuccess: null,
    onError: null,
    successMessage: null,
    errorMessage: null,
    invalidateQueries: () => [],
  }
) => {
  const {
    onSuccess,
    onError,
    successMessage,
    errorMessage,
    invalidateQueries = () => [],
  } = config

  const [accessToken] = useAccessTokenContext()
  const queryClient = useQueryClient()
  const { showSuccess, showError } = useAlert()

  return useMutation({
    mutationFn: (data) => queryFunc({ accessToken, ...data }),
    onSuccess: (...data) => {
      if (onSuccess) {
        onSuccess(...data)
      }
      if (successMessage) {
        showSuccess({ text: successMessage })
      }
      invalidateQueries(...data).forEach((queryKey) => {
        queryClient.invalidateQueries({ queryKey })
      })
    },
    onError: (...data) => {
      if (onError) {
        onError(data)
      }
      if (errorMessage) {
        showError({ text: errorMessage })
      }
    },
  })
}
