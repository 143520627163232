import { Text } from '../../../../../../components'
import { Row } from '../../../../../../containers'
import { formatBodyWeight, formatWeekday } from '../../../../../../utils/format'

const WeeklyBodyWeightListItem = ({ date, weight }) => {
  return (
    <Row sx={{ justifyContent: 'space-between' }}>
      <Text>{formatWeekday(new Date(date))}</Text>
      <Text>{formatBodyWeight(weight)}</Text>
    </Row>
  )
}

export default WeeklyBodyWeightListItem
