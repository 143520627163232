import { fetchApi } from '../../utils'

export const getTrends = async ({ accessToken, days, endDate }) => {
  const { hrv, moods, dailyActivities, body } = await fetchApi(`/trends`, {
    accessToken,
    params: {
      days,
      endDate,
    },
  })
  return { hrv, moods, dailyActivities, body }
}
