import { MyFitAppApi } from '@apis/MyFitApp'
import { useAccessTokenContext } from '@contexts'
import { useCallback, useEffect, useState } from 'react'

export const useGetMoods = ({ date }) => {
  const [accessToken] = useAccessTokenContext()
  const [pending, setPending] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [moods, setMoods] = useState([])
  const [fetchCounter, setFetchCounter] = useState(0)

  const getMoods = useCallback(async () => {
    setPending(true)
    try {
      const moods = await MyFitAppApi.getMoods({
        accessToken,
        date,
      })
      setMoods(moods)
    } catch (error) {
      //showError({ text: "could not load moods" });
    }
    setPending(false)
    setInitialized(true)
  }, [accessToken, date])

  const refetch = () => setFetchCounter(fetchCounter + 1)

  useEffect(() => getMoods(), [getMoods, fetchCounter])

  return {
    getMoods,
    refetch,
    initialized,
    moods,
    pending,
  }
}
