import { CardContent, PendingButton } from '@components'
import useDialog from '../../../../hooks/useDialog/useDialog'
import WorkoutDialog from '../WorkoutDialog/WorkoutDialog'

export const StartWorkoutCardContent = ({
  isLoading,
  onStartWorkout,
  workoutTypes,
}) => {
  const [isOpen, openDialog, closeDialog] = useDialog()

  return (
    <CardContent aria-label="Start Workout Card Content">
      <PendingButton
        onClick={openDialog}
        aria-label="Open Start Workout Dialog"
        pending={isLoading}
      >
        Start Workout
      </PendingButton>
      <WorkoutDialog
        open={isOpen}
        onClose={closeDialog}
        onConfirm={onStartWorkout}
        workoutTypes={workoutTypes}
      />
    </CardContent>
  )
}
