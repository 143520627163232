import { useState } from 'react'

export function useStoredState(
  key,
  config = { storage: localStorage, defaultValue: null }
) {
  const { defaultValue = null, storage = localStorage } = config
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = storage.getItem(key)
      // Parse stored json or if none return defaultValue
      return item ? JSON.parse(item) : defaultValue
    } catch (error) {
      // If error also return defaultValue
      console.log(error)
      return defaultValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to storage.
  const setValue = (value) => {
    try {
      const valueToStore =
        typeof value === 'object' && !Array.isArray(value)
          ? { ...storedValue, ...value }
          : value
      // Save to local storage
      storage.setItem(key, JSON.stringify(valueToStore))
      // Save state
      setStoredValue(valueToStore)
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  return [storedValue, setValue]
}
