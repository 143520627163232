import { List } from '@components'
import { ExerciseListItem } from '@modules/Exercises/components'

export const ExerciseList = ({ exercises, isLoading, onSelect }) => {
  return (
    <List
      isLoading={isLoading}
      items={exercises}
      renderListItem={(exercise) => (
        <ExerciseListItem
          key={exercise.id}
          exercise={exercise}
          onClick={onSelect}
        />
      )}
    />
  )
}
