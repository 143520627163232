import { useState } from 'react'
import useSearchFilter from '../useSearchFilter/useSearchFilter'

const useSearch = (items = [], searchFields = []) => {
  const [searchValue, setSearchValue] = useState('')

  const filteredItems = useSearchFilter(items, searchValue, searchFields)

  return [filteredItems, setSearchValue]
}

export default useSearch
