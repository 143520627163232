import { MyFitAppApi } from '@apis/MyFitApp'
import { useGetQuery } from '@hooks'

export const useGetTrainingPlan = (id) => {
  const { data: trainingPlan = null, ...result } = useGetQuery(
    ['trainingPlans', id],
    ({ accessToken }) => MyFitAppApi.getTrainingPlan({ accessToken, id })
  )

  return {
    trainingPlan,
    ...result,
  }
}
