import { CardContent, PendingButton } from '@components'
import useDialog from '../../../../hooks/useDialog/useDialog'
import EditWorkoutDialog from '../EditWorkoutDialog/EditWorkoutDialog'

export const CreateWorkoutCardContent = ({
  onCreateWorkout,
  workoutTypes,
  isLoading,
  workout,
}) => {
  const [isOpen, openDialog, closeDialog] = useDialog()

  return (
    <CardContent aria-label="Create Workout Card">
      <PendingButton
        onClick={openDialog}
        aria-label="Open Create Workout Dialog"
        pending={isLoading}
      >
        Create Workout
      </PendingButton>
      <EditWorkoutDialog
        open={isOpen}
        onClose={closeDialog}
        onConfirm={onCreateWorkout}
        workoutTypes={workoutTypes}
        workout={workout}
      />
    </CardContent>
  )
}
