import { MyFitAppApi } from '@apis/MyFitApp'
import { useDateContext } from '@contexts'
import { usePostQuery } from '@hooks'

export const useDeleteWorkout = () => {
  const { isoDate, lastDays } = useDateContext()

  const { mutate: deleteWorkout, ...rest } = usePostQuery(
    ({ accessToken, id }) => {
      return MyFitAppApi.deleteWorkout({
        accessToken,
        workoutId: id,
      })
    },
    {
      invalidateQueries: () => [
        ['workouts', isoDate],
        ['workouts', lastDays.startDate, lastDays.endDate],
      ],
    }
  )

  return {
    deleteWorkout,
    ...rest,
  }
}
