import { MyFitAppApi } from '@apis/MyFitApp'
import { useGetQuery } from '@hooks'

export const useGetWorkoutById = ({ id }) => {
  const { data: workout, ...result } = useGetQuery(
    ['workouts', id],
    async ({ accessToken }) => {
      return await MyFitAppApi.getWorkout({
        accessToken,
        id,
      })
    }
  )

  return {
    workout,
    ...result,
  }
}
