import { MyFitAppApi } from '@apis/MyFitApp'
import { usePostQuery } from '@hooks'

export const useDeleteGym = () => {
  const { mutate: deleteGym, ...rest } = usePostQuery(
    ({ accessToken, id }) => MyFitAppApi.deleteGym({ accessToken, id }),
    {
      invalidateQueries: () => [['gyms']],
    }
  )

  return {
    deleteGym,
    ...rest,
  }
}
