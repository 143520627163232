import { ListItem as MaterialListItem } from '@mui/material'

export const ListItem = ({ children, ...props }) => {
  return (
    <MaterialListItem
      sx={{ mb: 1, borderRadius: 5, bgcolor: 'background.card' }}
      {...props}
    >
      {children}
    </MaterialListItem>
  )
}
