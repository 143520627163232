import useBodyWeight from '../../hooks/useBodyWeight/useBodyWeight'
import BodyWeightDialog from '../../components/BodyWeightDialog/BodyWeightDialog'
import { formatDate } from '../../../../utils/format'
import useDialog from '../../../../hooks/useDialog/useDialog'
import BodyWeightCardComponent from '../../components/BodyWeightCard/BodyWeightCard'

const BodyWeightCard = ({ date }) => {
  const [isDialogOpen, openDialog, closeDialog] = useDialog(false)

  const { createBodyWeight, deleteBodyWeight, bodyWeight, loading } =
    useBodyWeight({ date })

  return (
    <>
      <BodyWeightCardComponent
        loading={loading}
        bodyWeight={bodyWeight}
        onDelete={deleteBodyWeight}
        onCreate={openDialog}
      />
      <BodyWeightDialog
        title={formatDate(date)}
        open={isDialogOpen}
        onClose={closeDialog}
        onConfirm={createBodyWeight}
      />
    </>
  )
}

export default BodyWeightCard
