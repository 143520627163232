import { useMemo } from 'react'
import { calcWeek } from '@utils/date'

const useWeek = (date) => {
  const [startDate, endDate] = useMemo(() => calcWeek(date), [date])

  return [startDate, endDate]
}

export default useWeek
