import { Page } from '@containers'
import { ExercisePicker } from '@modules/Exercises/containers'

export const TestPage = () => {
  return (
    <Page>
      <ExercisePicker />
    </Page>
  )
}
