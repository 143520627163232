import { withProgress } from '@components'
import { TrainingPlanSelect as TrainingPlanSelectComponent } from '@modules/TrainingPlans/components'
import { withGetTrainingPlans } from '@modules/TrainingPlans/hocs'

export const TrainingPlanSelect = withGetTrainingPlans(
  withProgress(({ control, trainingPlans, rules, helperText }) => {
    return (
      <TrainingPlanSelectComponent
        control={control}
        trainingPlans={trainingPlans}
        rules={rules}
        helperText={helperText}
      />
    )
  })
)
