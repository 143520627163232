import { useStopWorkout } from '@modules/Workout/hooks'
import { useNavigate } from 'react-router-dom'
import { OpenWorkoutCardContent as OpenWorkoutCardContentComponent } from '@modules/Workout/components'

export const OpenWorkoutCardContent = ({ isLoading, workout }) => {
  const navigate = useNavigate()
  const stopWorkoutApi = useStopWorkout()

  const handleJoinWorkout = (workout) => {
    navigate(`/workouts/${workout.id}`, { state: { workout } })
  }

  const loading = isLoading || stopWorkoutApi.isLoading

  return (
    <OpenWorkoutCardContentComponent
      workout={workout}
      isLoading={loading}
      onStopWorkout={stopWorkoutApi.stopWorkout}
      onJoinWorkout={handleJoinWorkout}
    />
  )
}
