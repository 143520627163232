import { Divider } from '@mui/material'
import { WeeklyBodyWeightListFooter, WeeklyBodyWeightListItem } from '..'

const WeeklyBodyWeightList = ({ bodyWeights, weekAverage }) => {
  return (
    <>
      {bodyWeights &&
        bodyWeights.map((bodyWeight, i) => {
          const { id, date, weight } = bodyWeight
          return (
            <WeeklyBodyWeightListItem
              key={id}
              date={date}
              weight={weight}
            />
          )
        })}
      <Divider sx={{ mt: 0.5 }} />
      <WeeklyBodyWeightListFooter weekAverage={weekAverage} />
    </>
  )
}

export default WeeklyBodyWeightList
