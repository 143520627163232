import { Theme } from './containers'
import { BrowserRouter } from 'react-router-dom'
import { Suspense } from 'react'
import {
  DateProvider,
  ThemeProvider,
  UserProvider,
  AccessTokenProvider,
  AlertProvider,
} from '@contexts'

const withProviders = (Component) => (props) =>
  (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <ThemeProvider>
          <Theme>
            <AccessTokenProvider>
              <AlertProvider>
                <DateProvider>
                  <UserProvider>
                    <Component {...props} />
                  </UserProvider>
                </DateProvider>
              </AlertProvider>
            </AccessTokenProvider>
          </Theme>
        </ThemeProvider>
      </Suspense>
    </BrowserRouter>
  )

export default withProviders
