import useQueryParams from '@hooks/useQueryParams/useQueryParams'
import { isAccessTokenValid } from '@utils/token'
import { createContext, useContext } from 'react'
import { useStoredState } from '../../hooks/use-stored-state.hook'

const AccessTokenContext = createContext()

const useAccessTokenContextValue = () => {
  const [storedAccessToken, setAccessToken] = useStoredState('accessToken')
  const queryParamAccessToken = useQueryParams('accessToken')

  const accessToken = isAccessTokenValid(queryParamAccessToken)
    ? queryParamAccessToken
    : isAccessTokenValid(storedAccessToken)
    ? storedAccessToken
    : null

  return {
    accessToken,
    setAccessToken,
  }
}

export const AccessTokenProvider = ({ children }) => {
  const value = useAccessTokenContextValue()
  return (
    <AccessTokenContext.Provider value={value}>
      {children}
    </AccessTokenContext.Provider>
  )
}

export const useAccessTokenContext = () => {
  const context = useContext(AccessTokenContext)

  if (!context)
    throw new Error('AccessTokenContext must be inside a AccessTokenProvider')

  const { accessToken, setAccessToken } = context

  return [accessToken, setAccessToken]
}
