import { MyFitAppApi } from '@apis/MyFitApp'
import { useAccessTokenContext } from '@contexts'
import useAlert from '@hooks/useAlert/useAlert'
import { useCallback, useState } from 'react'

export const useCreateMood = () => {
  const [accessToken] = useAccessTokenContext()
  const { showError } = useAlert()
  const [pending, setPending] = useState()

  const createMood = useCallback(
    async ({ date, sleep, mood, motivation, soreness }) => {
      setPending(true)
      try {
        await MyFitAppApi.createMood({
          accessToken,
          date,
          sleep,
          mood,
          motivation,
          soreness,
        })
      } catch (error) {
        showError({ text: 'mood could not be saved!' })
      }
      setPending(false)
    },
    []
  )

  return {
    createMood,
    pending,
  }
}
