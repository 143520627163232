import { CardContent, withCircularProgress } from '../../../../../../components'
import WeeklyBodyWeightList from '../WeeklyBodyWeightList/WeeklyBodyWeightList'

const WeeklyBodyWeightCardContent = ({ bodyWeights, weekAverage }) => {
  return (
    <CardContent>
      <WeeklyBodyWeightList
        bodyWeights={bodyWeights}
        weekAverage={weekAverage}
      />
    </CardContent>
  )
}

const circularProgressProps = {
  'aria-label': 'load Weekly Body Weights',
}

export default withCircularProgress(
  WeeklyBodyWeightCardContent,
  circularProgressProps
)
