import { StartWorkoutCardContent as StartWorkoutCardContentComponent } from '@modules/Workout/components'
import { useStartWorkout } from '@modules/Workout/hooks'

export const StartWorkoutCardContent = ({ workoutTypes }) => {
  const { isLoading, startWorkout } = useStartWorkout()

  return (
    <StartWorkoutCardContentComponent
      isLoading={isLoading}
      workoutTypes={workoutTypes}
      onStartWorkout={startWorkout}
    />
  )
}
