import { CardContent } from '@components'
import {
  Delete,
  DirectionsBike,
  Edit,
  FitnessCenter,
  Share,
  SportsBasketball,
} from '@mui/icons-material'

import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import { useState } from 'react'
import { formatDuration } from '@utils/format'
import EditWorkoutDialog from '../EditWorkoutDialog/EditWorkoutDialog'

export const WorkoutHistoryCardContent = ({
  isLoading,
  workoutTypes,
  closedWorkouts,
  onJoinWorkout,
  onDeleteWorkout,
  onEditWorkout,
  onShareWorkout,
}) => {
  const [workout, setWorkout] = useState(null)

  const handleCloseDialog = () => {
    setWorkout(null)
  }

  const isOpen = Boolean(workout)

  return (
    <CardContent isLoading={isLoading}>
      <List>
        {closedWorkouts.map((workout) => (
          <ClosedWorkoutListItem
            key={workout.id}
            workout={workout}
            onJoinWorkout={onJoinWorkout}
            onEditWorkout={setWorkout}
            onDeleteWorkout={onDeleteWorkout}
            onShareWorkout={onShareWorkout}
          />
        ))}
      </List>
      <EditWorkoutDialog
        open={isOpen}
        onClose={handleCloseDialog}
        onConfirm={onEditWorkout}
        workoutTypes={workoutTypes}
        workout={workout}
      />
    </CardContent>
  )
}

const ClosedWorkoutListItem = ({
  workout,
  onJoinWorkout,
  onDeleteWorkout,
  onEditWorkout,
  onShareWorkout,
}) => {
  const handleJoinWorkout = () => {
    onJoinWorkout(workout)
  }

  const handleEditWorkout = () => {
    onEditWorkout(workout)
  }

  const handleDeleteWorkout = () => {
    onDeleteWorkout(workout)
  }

  const handleShareWorkout = () => {
    onShareWorkout(workout)
  }

  const duration = formatDuration(
    new Date(workout.startDate),
    new Date(workout.endDate)
  )

  return (
    <ListItem
      sx={{ mb: 1, borderRadius: 5, bgcolor: 'background.card' }}
      onClick={handleJoinWorkout}
      button
    >
      <ListItemIcon sx={{ minWidth: 40 }}>
        {workout.type.value === 'WEIGHT_TRAINING' ||
        workout.trainingPlanDayId !== null ? (
          <FitnessCenter />
        ) : workout.type.value === 'CARDIO' ? (
          <DirectionsBike />
        ) : (
          <SportsBasketball />
        )}
      </ListItemIcon>
      <ListItemText
        primary={workout.name}
        secondary={duration}
        primaryTypographyProps={{ sx: { color: 'text.primary' } }}
        secondaryTypographyProps={{ sx: { color: 'text.primary' } }}
      />
      <ListItemSecondaryAction>
        <IconButton
          aria-label="Edit Workout"
          onClick={handleEditWorkout}
        >
          <Edit />
        </IconButton>
        <IconButton
          aria-label="Delete Workout"
          onClick={handleDeleteWorkout}
        >
          <Delete />
        </IconButton>
        <IconButton onClick={handleShareWorkout}>
          <Share />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
