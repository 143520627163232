import { useDeleteGym } from '@modules/Gyms/hooks/useDeleteGym/useDeleteGym'

export const withDeleteGym =
  (Component) =>
  ({ isLoading = false, ...props }) => {
    const deleteGymApi = useDeleteGym()

    return (
      <Component
        isLoading={isLoading || deleteGymApi.isLoading}
        onDeleteGym={deleteGymApi.deleteGym}
        {...props}
      />
    )
  }
