import { useAccessTokenContext } from '@contexts'
import { useQuery } from '@tanstack/react-query'

export const useGetQuery = (key, queryFunc, config = {}) => {
  const [accessToken] = useAccessTokenContext()

  const result = useQuery(key, () => queryFunc({ accessToken }), {
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...config,
  })

  return result
}
