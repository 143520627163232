import { VisibilityOff, Visibility } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import { forwardRef, useState } from 'react'
import { TextField } from '..'

const PasswordField = forwardRef(({ label = 'Password', ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <TextField
      ref={ref}
      label={label}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleToggleShowPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
})

export default PasswordField
