import { Dialog } from '../../../../components'
import StartWorkoutForm from '../StartWorkoutForm/StartWorkoutForm'

const WorkoutDialog = ({ onClose, onConfirm, workoutTypes, ...props }) => {
  const handleConfirm = (data) => {
    onConfirm(data)
    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog
      title="Start Workout"
      onClose={onClose}
      {...props}
    >
      <StartWorkoutForm
        onSubmit={handleConfirm}
        workoutTypes={workoutTypes}
      />
    </Dialog>
  )
}

export default WorkoutDialog
