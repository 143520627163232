import { Select } from '@components'
import { MenuItem } from '@mui/material'

export const TrainingPlanDaySelect = ({
  control,
  trainingPlanDays,
  rules,
  helperText,
  label = 'Training Day',
  id = 'training-plan-day-select',
  name = 'trainingPlanDayId',
  ...props
}) => {
  return (
    <Select
      id={id}
      label={label}
      name={name}
      control={control}
      rules={rules}
      helperText={helperText}
      {...props}
    >
      {trainingPlanDays.map(({ id, name }) => {
        return (
          <MenuItem
            key={id}
            value={id}
          >
            {name}
          </MenuItem>
        )
      })}
    </Select>
  )
}
