import { Select } from '@components'
import { MenuItem } from '@mui/material'
import { useMemo } from 'react'

export const GymSelect = ({
  control,
  gyms,
  rules,
  helperText,
  label = 'Gym',
  id = 'gym-select',
  name = 'gymId',
  ...props
}) => {
  const defaultValue = useMemo(() => {
    const homeGym = gyms.find((gym) => gym.isHomeGym)
    return homeGym ? homeGym.id : null
  }, [gyms])

  return (
    <Select
      id={id}
      label={label}
      name={name}
      control={control}
      rules={rules}
      helperText={helperText}
      defaultValue={defaultValue}
      {...props}
    >
      {gyms.map(({ id, name }) => {
        return (
          <MenuItem
            key={id}
            value={id}
          >
            {name}
          </MenuItem>
        )
      })}
    </Select>
  )
}
