import { MyFitAppApi } from '@apis/MyFitApp'
import { usePostQuery } from '@hooks'

export const useCreateGymExercise = ({ gymId, muscleId }) => {
  const { mutate: createGymExercise, ...rest } = usePostQuery(
    ({ accessToken, id }) =>
      MyFitAppApi.createGymExercise({ accessToken, gymId, exerciseId: id }),
    {
      invalidateQueries: () => [['gymExercises', gymId, muscleId]],
    }
  )

  return {
    createGymExercise,
    ...rest,
  }
}
