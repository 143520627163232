import { fetchApi } from '../../utils'

export const getGymExercises = async ({ accessToken, muscleId, gymId }) => {
  const { exercises } = await fetchApi(`/gyms/${gymId}/exercises`, {
    accessToken,
    params: {
      muscleId,
    },
  })
  return exercises
}
