import GymExercisesPage from '@pages/GymExercisesPage/GymExercisesPage'
import GymsPage from '@pages/GymsPage/GymsPage'
import { TestPage } from '@pages/Test/TestPage'
import EditTrainingPlanPage from '@pages/TrainingPlansPage/pages/EditTrainingPlanPage'
import DashboardPage from './pages/DashboardPage/DashboardPage'
import { lazyLoadPage } from './utils/file'

const ExercisesPage = lazyLoadPage('ExercisesPage')
const FoodsPage = lazyLoadPage('FoodsPage')
const LoginPage = lazyLoadPage('LoginPage')
const MusclesPage = lazyLoadPage('MusclesPage')
const SignUpPage = lazyLoadPage('SignUpPage')
const WorkoutTrackerPage = lazyLoadPage('WorkoutTrackerPage')
const WorkoutsPage = lazyLoadPage('WorkoutsPage')
const MenuPage = lazyLoadPage('MenuPage')
const ForgotPasswordPage = lazyLoadPage('ForgotPasswordPage')
const ResetPasswordPage = lazyLoadPage('ResetPasswordPage')
const SettingsPage = lazyLoadPage('SettingsPage')
const TrainingPlansPage = lazyLoadPage('TrainingPlansPage')

const ROUTES = {
  public: [
    {
      path: '/login',
      page: LoginPage,
    },
    {
      path: '/signup',
      page: SignUpPage,
    },
    {
      path: '/forgotPassword',
      page: ForgotPasswordPage,
    },
    {
      path: '/resetPassword',
      page: ResetPasswordPage,
    },
  ],
  private: [
    {
      path: '/',
      page: DashboardPage,
    },
    {
      path: '/workouts/:workoutId',
      page: WorkoutsPage,
    },
    {
      path: '/muscles',
      page: MusclesPage,
    },
    {
      path: '/exercises',
      page: ExercisesPage,
    },
    {
      path: '/workout-tracker',
      page: WorkoutTrackerPage,
    },
    {
      path: '/foods',
      page: FoodsPage,
    },
    {
      path: '/menu',
      page: MenuPage,
    },
    {
      path: '/settings',
      page: SettingsPage,
    },
    {
      path: '/trainingPlans',
      page: TrainingPlansPage,
    },
    {
      path: '/trainingPlans/:trainingPlanId',
      page: EditTrainingPlanPage,
    },
    {
      path: '/test',
      page: TestPage,
    },
    {
      path: '/gyms',
      page: GymsPage,
    },
    {
      path: '/gyms/:gymId/exercises',
      page: GymExercisesPage,
    },
  ],
}

export default ROUTES
