import { Button, Form, TextField } from '@components'
import { useForm } from 'react-hook-form'

export const GymForm = ({ onSubmit, defaultValues }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ defaultValues })

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      sx={{ p: 1 }}
    >
      <TextField
        label="Name"
        helperText={errors?.name?.message}
        autoFocus
        {...register('name', { required: 'Name required' })}
      />
      <Button
        type="submit"
        sx={{ mt: 2 }}
      >
        SAVE
      </Button>
    </Form>
  )
}
