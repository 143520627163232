import { useCreateGymExercise } from '@modules/Gyms/hooks'

export const withAddGymExercise =
  (Component) =>
  ({ isLoading = false, ...props }) => {
    const { gymId, muscleId = null } = props

    const createGymExerciseApi = useCreateGymExercise({ gymId, muscleId })

    return (
      <Component
        isLoading={isLoading || createGymExerciseApi.isLoading}
        onAddGymExercise={createGymExerciseApi.createGymExercise}
        {...props}
      />
    )
  }
