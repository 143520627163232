import { ListItemText as MaterialListItemText } from '@mui/material'

export const ListItemText = ({ primary, secondary }) => {
  return (
    <MaterialListItemText
      primary={primary}
      secondary={secondary}
      primaryTypographyProps={{ sx: { color: 'text.primary' } }}
      secondaryTypographyProps={{ sx: { color: 'text.primary' } }}
    />
  )
}
