import { Box } from '@mui/material'
import { forwardRef } from 'react'

const Row = forwardRef(({ sx, children, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      sx={{ display: 'flex', alignItems: 'center', ...sx }}
      {...props}
    >
      {children}
    </Box>
  )
})

export default Row
