import { Dialog } from '@components'
import useDialog from '@hooks/useDialog/useDialog'
import { Add } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { ExericseList } from '@modules/Exercises/containers'
import { Column } from '@containers'
import { MuscleTabs } from '@modules/Muscles/containers'
import { useState } from 'react'

export const ExercisePicker = ({ onSelect }) => {
  const [isOpen, openDialog, closeDialog] = useDialog()
  const [muscleId, setMuscleId] = useState(1)

  const handleChangeMuscleId = (muscleId) => {
    setMuscleId(muscleId)
  }

  const handleSelect = (exercise) => {
    onSelect(exercise)
    closeDialog()
  }

  return (
    <>
      <IconButton onClick={openDialog}>
        <Add />
      </IconButton>
      <Dialog
        open={isOpen}
        title="Exercises"
        onClose={closeDialog}
        PaperProps={{ sx: { minHeight: '80%' } }}
      >
        <Column>
          <MuscleTabs
            onChange={handleChangeMuscleId}
            value={muscleId}
          />
          <ExericseList
            onSelect={handleSelect}
            muscleId={muscleId}
          />
        </Column>
      </Dialog>
    </>
  )
}
