import { useState } from 'react'
import { Page, Column } from '../../containers'
import { DatePicker, ToggleTabs } from '../../components'
import { BodyFatCard } from '../../modules/BodyFat'
import {
  BodyWeightCard,
  MonthlyBodyWeightCard,
  WeeklyBodyWeightCard,
} from '../../modules/BodyWeight'
import { MuscleWorkloadCard } from '../../modules/Muscles'
import { useNavigate } from 'react-router-dom'
import { Menu } from '@mui/icons-material'
import { Fab } from '@mui/material'
import { MoodDialog } from '@modules/Mood'
import { LastWorkoutCards } from '@modules/Workout/containers'
import { TrendCards } from '@modules/Trends/containers'
import {
  WorkoutManagerCard,
  WorkoutHistoryCard,
} from '@modules/Workout/containers'
import { useDateContext } from '@contexts'

const DashboardPage = () => {
  const { date, startDate, endDate, increaseDate, decreaseDate, setDate } =
    useDateContext()
  const [category, setCategory] = useState('TRAINING')
  const navigate = useNavigate()

  const handleOpenMenu = () => {
    navigate('/menu')
  }

  return (
    <Page>
      <DatePicker
        date={date}
        onDecreaseDate={decreaseDate}
        onIncreaseDate={increaseDate}
      />
      <LastWorkoutCards
        date={date}
        onSelect={setDate}
      />
      <ToggleTabs
        items={[
          {
            label: 'Training',
            value: 'TRAINING',
          },
          {
            label: 'Trends',
            value: 'TRENDS',
          },
          {
            label: 'Body',
            value: 'BODY',
          },
        ]}
        onChange={setCategory}
        value={category}
      />
      <Column sx={{ p: 1, pt: 0, overflowY: 'scroll' }}>
        {category === 'TRAINING' ? (
          <>
            <WorkoutManagerCard date={date} />
            <WorkoutHistoryCard date={date} />
            {/* <WorkoutCard date={date} /> */}
            <MuscleWorkloadCard
              startDate={startDate}
              endDate={endDate}
            />
          </>
        ) : category === 'TRENDS' ? (
          <>
            <TrendCards date={date} />
          </>
        ) : (
          category === 'BODY' && (
            <>
              <BodyWeightCard date={date} />
              <BodyFatCard date={date} />
              <WeeklyBodyWeightCard
                startDate={startDate}
                endDate={endDate}
              />
              <MonthlyBodyWeightCard />
            </>
          )
        )}
      </Column>
      {category === 'TRAINING' && (
        <Fab
          color="primary"
          onClick={handleOpenMenu}
          aria-label="Menu Fab"
          sx={{ position: 'absolute', bottom: 24, right: 24 }}
        >
          <Menu />
        </Fab>
      )}

      <MoodDialog />
    </Page>
  )
}

export default DashboardPage
