import { PendingButton, Text } from '@components'
import useCurrentDateTime from '@hooks/useCurrentDateTime/useCurrentDateTime'
import { useStopWorkout } from '@modules/Workout/hooks'
import { formatDuration } from '@utils/format'

export const DurationCard = ({ workout }) => {
  const isClosed = workout?.endDate !== null

  return isClosed ? (
    <DurationText workout={workout} />
  ) : (
    <StopWorkoutButton workout={workout} />
  )
}

const DurationText = ({ workout }) => {
  const workoutDuration = formatDuration(
    new Date(workout.startDate),
    new Date(workout.endDate)
  )

  return <Text>{workoutDuration}</Text>
}

const StopWorkoutButton = ({ workout }) => {
  const { stopWorkout, isLoading } = useStopWorkout()
  const currentDatetime = useCurrentDateTime()

  const handleStopWorkout = () => {
    if (window.confirm('do yout really want to stop the workout?')) {
      stopWorkout(workout)
    }
    stopWorkout(workout)
  }

  const workoutDuration = formatDuration(
    new Date(workout.startDate),
    currentDatetime
  )

  return (
    <PendingButton
      size="small"
      color="secondary"
      pending={isLoading}
      onClick={handleStopWorkout}
    >
      {workoutDuration}
    </PendingButton>
  )
}
